import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNichePerson(
  id,
  { image = false, addresses = false, type } = {},
) {
  const queryKey = ['nichePeople', id, image, addresses, type];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const searchParams = new URLSearchParams({
        image,
        addresses,
      });

      return briefingApi
        .get(`niche/people/${id}${type ? `/${type}` : ''}`, {
          searchParams,
          signal,
        })
        .json();
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
