import { useNotices } from '@/hooks';
import { spin } from '@/utils';
import {
  Add as AddIcon,
  Autorenew as AutorenewIcon,
  Done as DoneIcon,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Notice } from './Notice';
import { NoticeEditor } from './NoticeEditor';

export function InspectorsNoticeBoard({ groupCodes, onComplete }) {
  const {
    data: notices,
    isFetching,
    isLoading,
    refetch,
  } = useNotices(groupCodes);
  const [editCode, setEditCode] = useState(null);

  const handleSettingsClick = (code) => () => {
    setEditCode(code);
  };

  function handleRefreshClick() {
    refetch();
  }

  function handleAddClick() {
    setEditCode('new');
  }

  function onEditorClose() {
    setEditCode(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Box sx={{ px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={(theme) => ({
          alignItems: 'center',
          position: 'sticky',
          top: 48,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          zIndex: 700,
          pl: 1,
          py: 1,
        })}
      >
        <Typography variant="h6">Inspectors Notice Board</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Tooltip title="Refresh" placement="bottom">
            <Box component="span">
              <IconButton
                onClick={handleRefreshClick}
                disabled={isFetching || isLoading}
              >
                <AutorenewIcon
                  fontSize="inherit"
                  sx={
                    isLoading || isFetching
                      ? { animation: `${spin} 2s linear infinite` }
                      : undefined
                  }
                />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="Add" placement="left">
            <IconButton size="small" onClick={handleAddClick}>
              <Avatar
                sx={{
                  color: 'secondary.contrastText',
                  bgcolor: 'secondary.main',
                  width: 32,
                  height: 32,
                  fontSize: 24,
                }}
              >
                <AddIcon fontSize="inherit" />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        {notices.map((notice) => (
          <Notice
            key={notice.code}
            notice={notice}
            onSettingsClick={handleSettingsClick(notice.code)}
          />
        ))}
      </Stack>
      <NoticeEditor
        code={editCode}
        isOpen={Boolean(editCode)}
        onClose={onEditorClose}
        groupCodes={groupCodes}
      />
    </Box>
  );
}
