import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useTelematicsBox(id) {
  const queryKey = ['telematicsBoxes', id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (id === 'new') {
        return {
          label: '',
        };
      }

      const json = [
        { $match: { imei: id } },
        {
          $project: {
            imei: true,
            imsi: true,
            type: true,
            label: true,
            firstContactTime: true,
            lastPoll: '$mostRecentPoll',
            lastEdit: true,
          },
        },
        {
          $lookup: {
            from: 'vehicles',
            localField: '_id',
            foreignField: 'telematicsBoxImei',
            as: 'vehicles',
          },
        },
        {
          $set: {
            vehicles: {
              $filter: {
                input: '$vehicles',
                as: 'vehicle',
                cond: { $ne: ['$$vehicle.deleted', true] },
              },
            },
          },
        },
      ];

      const telematicsBoxes = await api
        .post('pipeline/telematicsBoxes', { json, signal })
        .json();

      log('Read', 'Telematics Boxes', { id });

      return telematicsBoxes[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
