import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useUserQueries({ userId, collection }) {
  const queryKey = ['userQueries', userId, collection];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        { $match: { 'created.userId': userId, collection } },
        { $sort: { lastEdit: -1 } },
      ];

      log('Read', 'User Queries');

      return api.post('pipeline/userQueries', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!userId,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
