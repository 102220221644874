import { api } from '@/apis';
import { disableNotifications } from '@/utils/config';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNotifications() {
  const queryKey = ['notifications'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      return api
        .post('pipeline/userNotifications', {
          json: [
            {
              $project: {
                identifier: true,
                time: true,
                user: true,
                type: true,
                title: true,
                message: true,
              },
            },
            { $sort: { time: -1 } },
          ],
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !disableNotifications,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
