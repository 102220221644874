import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfToday, subDays } from 'date-fns';

export function useAverageInStationTime(groupCode, subgroupType, days) {
  const queryKey = ['inStationTime', groupCode, subgroupType, days];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $match: {
            time: {
              $gte: subDays(startOfToday(), days),
              $lt: startOfToday(),
            },
            'vehicle.groupCodes': groupCode,
          },
        },
        {
          $lookup: {
            from: 'groups',
            localField: 'vehicle.groupCodes',
            foreignField: 'code',
            as: 'group',
            pipeline: [{ $match: { type: subgroupType } }],
          },
        },
        {
          $unwind: {
            path: '$group',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: { $ifNull: ['$group.code', 'UNKNOWN'] },
            stoppedBaseSeconds: {
              $avg: '$stopped.all.baseSeconds',
            },
          },
        },
        {
          $project: {
            _id: false,
            group: '$_id',
            hours: { $divide: ['$stoppedBaseSeconds', 3600] },
          },
        },
        { $sort: { hours: -1 } },
      ];

      return api
        .post('pipeline/vehicleDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!subgroupType,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
