import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfWeek } from 'date-fns';

export function useCurrentObjectiveCounts(groupType, groupCode) {
  const queryKey = ['currentObjectiveCounts', groupType, groupCode];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const weekStart = startOfWeek(new Date());
      const json = [
        { $match: { endTime: { $gt: weekStart } } },
        {
          $group: {
            _id: {
              identifier: '$objective.identifier',
              title: '$objective.title',
            },
            totalAttendances: { $sum: 1 },
            groupAttendances: {
              $sum: {
                $cond: [
                  { $in: [groupCode, { $ifNull: [`$person.groupCodes`, []] }] },
                  1,
                  0,
                ],
              },
            },
          },
        },
        { $match: { groupAttendances: { $gt: 0 } } },
        {
          $project: {
            _id: false,
            identifier: '$_id.identifier',
            title: '$_id.title',
            totalAttendances: true,
            groupAttendances: true,
          },
        },
        { $sort: { title: 1 } },
      ];

      return api
        .post('pipeline/personObjectiveAttendances', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
