import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useUrgentAction(code, defaultValue) {
  const queryKey = ['urgentActions', code, defaultValue];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      if (code === 'new') {
        return defaultValue;
      }

      return briefingApi.get(`urgent-actions/${code}`, { signal }).json();
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
