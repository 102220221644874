import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useVehicles() {
  const queryKey = ['vehicles'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
          },
        },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            disposalDate: true,
            picture: true,
            role: true,
            homeStation: true,
            driverIdLocation: true,
            installLocation: true,
            assignments: true,
            attributes: true,
            groupCodes: true,
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            isStale: {
              $cond: {
                if: '$ignitionOn',
                then: {
                  $lte: [
                    '$lastPollTime',
                    { $subtract: [new Date(), 1000 * 60 * 60] },
                  ],
                },
                else: {
                  $lte: [
                    '$lastPollTime',
                    { $subtract: [new Date(), 1000 * 60 * 60 * 24 * 7] },
                  ],
                },
              },
            },
            searchString: {
              $toLower: {
                $concat: [
                  { $ifNull: ['$identificationNumber', ''] },
                  '+',
                  { $ifNull: ['$registrationNumber', ''] },
                  '+',
                  { $ifNull: ['$fleetNumber', ''] },
                ],
              },
            },
          },
        },
      ];

      log('Read', 'Vehicles');

      return api.post('pipeline/vehicles', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
