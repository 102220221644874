import { round } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting, categoryTypes = []) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        vehicle: {
          identificationNumber: true,
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: categoryTypes.reduce(
            (acc, value) => ({
              ...acc,
              [value]: true,
            }),
            {},
          ),
          type: true,
        },
        maximumForces: true,
        accelerometerData: {
          $map: {
            input: '$accelerometerData',
            as: 'data',
            in: {
              position: '$$data.position',
              headingDegrees: '$$data.headingDegrees',
              time: '$$data.time',
              horizontalForce: '$$data.horizontalForce',
              verticalForce: '$$data.verticalForce',
              lateralForce: '$$data.lateralForce',
              speedMilesPerHour: {
                $cond: [
                  {
                    $eq: [
                      {
                        $millisecond: {
                          $dateFromString: {
                            dateString: '$$data.time',
                          },
                        },
                      },
                      0,
                    ],
                  },
                  {
                    $round: [
                      {
                        $multiply: [
                          '$$data.speedKilometresPerHour',
                          0.62137119,
                        ],
                      },
                      2,
                    ],
                  },
                  null,
                ],
              },
            },
          },
        },
        deviceProperties: { triggerPoint: true, time: true },
        durationSeconds: true,
        path: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      horizontalMin: { $min: '$maximumForces.horizontal' },
      horizontalMax: { $max: '$maximumForces.horizontal' },
      verticalMin: { $min: '$maximumForces.vertical' },
      verticalMax: { $max: '$maximumForces.vertical' },
      lateralMin: { $min: '$maximumForces.lateral' },
      lateralMax: { $max: '$maximumForces.lateral' },
    },
  },
  {
    $project: {
      _id: false,
      total: true,
      horizontal: {
        min: { $round: ['$horizontalMin', 2] },
        max: { $round: ['$horizontalMax', 2] },
      },
      vertical: {
        min: { $round: ['$verticalMin', 2] },
        max: { $round: ['$verticalMax', 2] },
      },
      lateral: {
        min: { $round: ['$lateralMin', 2] },
        max: { $round: ['$lateralMax', 2] },
      },
    },
  },
];

export const downloadPipelineFn =
  (categoryTypes = []) =>
  (query) => [
    { $match: query },
    {
      $project: {
        vehicle: {
          identificationNumber: true,
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: categoryTypes.reduce(
            (acc, value) => ({
              ...acc,
              [value]: true,
            }),
            {},
          ),
          type: true,
        },
        maximumForces: true,
        deviceProperties: {
          time: {
            $dateFromString: {
              dateString: '$deviceProperties.time',
            },
          },
        },
      },
    },
  ];

export const columnsFn = (footer, categories = []) => [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  ...categories.map(({ value, label }) => ({
    header: label,
    id: `vehicle.groups.${value}`,
    accessorFn: ({ vehicle: { groups = {} } = {} } = {}) =>
      groups[value]?.join(', ') ?? '',
  })),
  {
    header: 'Type',
    id: 'vehicle.type',
    accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Time',
    accessorKey: 'deviceProperties.time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Horizontal Force',
    accessorKey: 'maximumForces.horizontal',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 2)}g` : '0g',
    footer: `${round(footer?.horizontal?.min ?? 0, 2)}g : ${round(footer?.horizontal?.max ?? 0, 2)}g`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
  {
    header: 'Vertical Force',
    accessorKey: 'maximumForces.vertical',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 2)}g` : '0g',
    footer: `${round(footer?.vertical?.min ?? 0, 2)}g : ${round(footer?.vertical?.max ?? 0, 2)}g`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
  {
    header: 'Lateral Force',
    accessorKey: 'maximumForces.lateral',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 2)}g` : '0g',
    footer: `${round(footer?.lateral?.min ?? 0, 2)}g : ${round(footer?.lateral?.max ?? 0, 2)}g`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
];
