import { api } from '@/apis';
import { addPropertiesPrefixToMatch, isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function usePersonBoundaryVisits({ boundary, query: filter }) {
  const queryKey = ['personBoundaryVisits', boundary, filter];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { time, ...match } = filter;
      const featureMatch = addPropertiesPrefixToMatch(match);
      const featurePipeline = [
        {
          $match: {
            time,
            position: { $geoWithin: { $geometry: boundary } },
          },
        },
        {
          $project: {
            _id: false,
            ssi: true,
            time: true,
            coordinates: '$position.coordinates',
            orderNumber: true,
          },
        },
        {
          $group: {
            _id: '$ssi',
            polls: {
              $push: {
                time: '$time',
                coordinates: '$coordinates',
                orderNumber: '$orderNumber',
              },
            },
          },
        },
        {
          $project: {
            reduction: {
              $reduce: {
                input: {
                  $sortArray: { input: '$polls', sortBy: { orderNumber: 1 } },
                },
                initialValue: { visits: [], lastOrderNumber: null },
                in: {
                  $cond: {
                    if: {
                      $ne: [
                        { $add: ['$$value.lastOrderNumber', 1] },
                        '$$this.orderNumber',
                      ],
                    },
                    then: {
                      visits: {
                        $concatArrays: [
                          '$$value.visits',
                          [
                            {
                              // id: {
                              //   $concat: [
                              //     '$_id',
                              //     { $toString: { $size: '$$value.visits' } },
                              //   ],
                              // },
                              coordinates: ['$$this.coordinates'],
                              properties: {
                                ssi: '$_id',
                                startTime: '$$this.time',
                                endTime: '$$this.time',
                              },
                            },
                          ],
                        ],
                      },
                      lastOrderNumber: '$$this.orderNumber',
                    },
                    else: {
                      visits: {
                        $concatArrays: [
                          {
                            $slice: [
                              '$$value.visits',
                              { $subtract: [{ $size: '$$value.visits' }, 1] },
                            ],
                          },
                          [
                            {
                              coordinates: {
                                $concatArrays: [
                                  { $last: '$$value.visits.coordinates' },
                                  ['$$this.coordinates'],
                                ],
                              },
                              properties: {
                                ssi: '$_id',
                                startTime: {
                                  $last: '$$value.visits.properties.startTime',
                                },
                                endTime: '$$this.time',
                              },
                            },
                          ],
                        ],
                      },
                      lastOrderNumber: '$$this.orderNumber',
                    },
                  },
                },
              },
            },
          },
        },
        { $unset: ['reduction.lastOrderNumber'] },
        { $unwind: '$reduction.visits' },
        { $replaceRoot: { newRoot: '$reduction.visits' } },
        {
          $project: {
            type: 'Feature',
            properties: {
              ssi: true,
              startTime: true,
              endTime: true,
              durationSeconds: {
                $dateDiff: {
                  startDate: '$properties.startTime',
                  endDate: '$properties.endTime',
                  unit: 'second',
                },
              },
            },
            geometry: {
              type: {
                $cond: [
                  { $gt: [{ $size: '$coordinates' }, 1] },
                  'LineString',
                  'Point',
                ],
              },
              coordinates: {
                $cond: [
                  { $gt: [{ $size: '$coordinates' }, 1] },
                  '$coordinates',
                  { $arrayElemAt: ['$coordinates', 0] },
                ],
              },
            },
          },
        },
        {
          $lookup: {
            from: 'personHistory',
            localField: 'properties.ssi',
            foreignField: 'person.radioSsi',
            as: 'properties.person',
            let: { startTime: '$properties.startTime' },
            pipeline: [
              { $match: { $expr: { $lt: ['$time', '$$startTime'] } } },
              { $sort: { time: -1 } },
              { $limit: 1 },
              { $replaceRoot: { newRoot: '$person' } },
            ],
          },
        },
        {
          $set: {
            'properties.person': {
              $ifNull: [
                { $first: '$properties.person' },
                { radioSsi: '$properties.ssi' },
              ],
            },
          },
        },
        !isEmpty(featureMatch) && { $match: featureMatch },
        {
          $setWindowFields: {
            sortBy: { 'properties.startTime': 1 },
            output: {
              id: {
                $count: {},
                window: { documents: ['unbounded', 'current'] },
              },
            },
          },
        },
      ].filter(Boolean);

      log('Read', 'Person Boundary Visits', { boundary, query: filter });

      return api
        .post('pipeline/radioPolls', {
          json: featurePipeline,
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!boundary && !isEmpty(filter),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
