import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useSharedMissingPersons(groupCodes) {
  const queryKey = ['sharedMissingPersons', groupCodes];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );

      return briefingApi
        .get('shared-missing-persons', { searchParams, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
