import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useSetting(id, defaultValue) {
  const queryKey = ['settings', id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await api.get(`settings/${id}`, { signal }).json();

      return response.value;
    },
    placeholderData: (previousData) => previousData ?? defaultValue,
    staleTime: 1000 * 60 * 60 * 24,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
