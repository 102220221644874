import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useLocationSnapshot(time) {
  const queryKey = ['locationSnapshot', time];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      return api
        .get(`geofencesSnapshot/${time.toISOString()}`, { signal })
        .json();
    },
    placeholderData: (previousData) =>
      previousData ?? {
        locations: {
          type: 'FeatureCollection',
          features: [],
        },
        objectives: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
