import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfToday, subMonths } from 'date-fns';

export function useVehicleUtilisationList(
  groupCodes = [],
  months = 3,
  roles = [],
) {
  const endTime = startOfToday();
  const startTime = subMonths(endTime, months);

  const queryKey = [
    'vehicleUtilisationList',
    groupCodes,
    startTime,
    endTime,
    roles,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        {
          $match: {
            time: { $gte: startTime, $lt: endTime },
            ...(groupCodes.length > 0
              ? { 'vehicle.groupCodes': { $all: groupCodes } }
              : {}),
            ...(roles.length > 0 ? { 'vehicle.role': { $in: roles } } : {}),
          },
        },
        {
          $project: {
            time: true,
            vehicle: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              role: true,
              homeStation: true,
            },
            usedSeconds: {
              $subtract: [
                {
                  $sum: [
                    '$moving.all.durationSeconds',
                    '$stopped.all.elsewhereSeconds',
                  ],
                },
                {
                  $sum: ['$idle.all.baseSeconds', '$idle.all.workshopSeconds'],
                },
              ],
            },
            unusedSeconds: {
              $sum: ['$stopped.all.baseSeconds', '$idle.all.baseSeconds'],
            },
            unavailableSeconds: {
              $sum: [
                '$stopped.all.workshopSeconds',
                '$idle.all.workshopSeconds',
              ],
            },
            unaccountableSeconds: '$unaccountable.all.durationSeconds',
          },
        },
        {
          $group: {
            _id: {
              identificationNumber: '$vehicle.identificationNumber',
              fleetNumber: '$vehicle.fleetNumber',
              registrationNumber: '$vehicle.registrationNumber',
              role: '$vehicle.role',
              homeStation: '$vehicle.homeStation',
            },
            usedSeconds: { $sum: '$usedSeconds' },
            unusedSeconds: { $sum: '$unusedSeconds' },
            unavailableSeconds: { $sum: '$unavailableSeconds' },
            unaccountableSeconds: { $sum: '$unaccountableSeconds' },
          },
        },
        {
          $set: {
            totalSeconds: {
              $add: [
                '$usedSeconds',
                '$unusedSeconds',
                '$unavailableSeconds',
                '$unaccountableSeconds',
              ],
            },
          },
        },
        {
          $project: {
            _id: false,
            identificationNumber: '$_id.identificationNumber',
            fleetNumber: '$_id.fleetNumber',
            registrationNumber: '$_id.registrationNumber',
            role: '$_id.role',
            homeStation: '$_id.homeStation',
            usedPercentage: {
              $multiply: [{ $divide: ['$usedSeconds', '$totalSeconds'] }, 100],
            },
            unusedPercentage: {
              $multiply: [
                { $divide: ['$unusedSeconds', '$totalSeconds'] },
                100,
              ],
            },
            unavailablePercentage: {
              $multiply: [
                { $divide: ['$unavailableSeconds', '$totalSeconds'] },
                100,
              ],
            },
            unaccountablePercentage: {
              $multiply: [
                { $divide: ['$unaccountableSeconds', '$totalSeconds'] },
                100,
              ],
            },
          },
        },
      ];

      log('Read', 'Fleet Utilisation List', {
        groupCodes,
        startTime,
        endTime,
        roles,
      });

      return api
        .post('pipeline/vehicleDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 8,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
