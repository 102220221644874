import { briefingApi } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useSubjects(type, groupCodes = [], filter = {}) {
  const queryKey = ['subjects', type, groupCodes, filter];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      log('Read', 'Subjects', { type, groupCodes, filter });

      const searchParams = new URLSearchParams([
        ['type', type],
        ...Object.entries(filter).map(([key, value]) => [key, value]),
        ...groupCodes.map((code) => ['groupCodes', code]),
      ]);
      return briefingApi.get('subjects', { searchParams, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
