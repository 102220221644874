import { api } from '@/apis';
import { useOptions } from '@/hooks';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { TripsAccelerationSummariesCountParams } from './types';

export function useTripAccelerationSummariesCount({
  startTime,
  endTime,
  query: filter,
  mode,
  excludeExempt,
}: TripsAccelerationSummariesCountParams): UseQueryResult<number> & {
  cancel: () => void;
} {
  const { data: classifications } = useOptions('tripClassification', {
    restricted: true,
  });
  const exemptTripClassifications = useMemo(
    () => (classifications ?? []).map(({ value }) => value),
    [classifications],
  );

  const queryKey = [
    'tripAccelerationSummariesCount',
    startTime,
    endTime,
    filter,
    mode,
    excludeExempt,
    exemptTripClassifications,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const peopleResponse: { code: string }[] = await api
        .post('pipeline/people', {
          json: [{ $match: filter }, { $project: { _id: false, code: true } }],
        })
        .json();

      const response: { total: number }[] = await api
        .post('pipeline/vehicleTripAccelerationSummaries', {
          json: [
            {
              $match: {
                startTime: { $gte: startTime, $lt: endTime },
                durationSeconds: { $gte: 60 },
                'driver.code': {
                  $in: peopleResponse.map((person) => person.code),
                },
                ...(excludeExempt
                  ? { classification: { $nin: exemptTripClassifications } }
                  : {}),
                ...(mode === 'emergency'
                  ? { 'equipmentActivations.emergencyOn': true }
                  : mode === 'nonEmergency'
                    ? { 'equipmentActivations.emergencyOn': { $ne: true } }
                    : {}),
              },
            },
            { $count: 'total' },
          ],
          signal,
        })
        .json();

      return response[0]?.total ?? 0;
    },
    placeholderData: (previousData) => previousData ?? 0,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
