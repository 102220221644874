import { startCase } from '@/utils';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';

export function QueryListItem({
  title,
  lastEditTime,
  interval,
  selected,
  onItemClick,
  onItemDoubleClick,
  onDeleteClick,
}) {
  return (
    <Tooltip
      title={`Last edited ${format(lastEditTime, 'dd/MM/yyyy HH:mm')}`}
      placement="right"
    >
      <ListItem
        secondaryAction={
          <IconButton color="error" edge="end" onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          dense
          onDoubleClick={onItemDoubleClick}
          onClick={onItemClick}
          selected={selected}
          role={undefined}
        >
          <ListItemText
            primary={title}
            secondary={interval === '' ? 'Unscheduled' : startCase(interval)}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}
