import { LocationMapDialog } from '@/components/controls';
import { useAddUrgentActionUpdate, useUrgentActions } from '@/hooks';
import { spin } from '@/utils';
import {
  Add as AddIcon,
  Autorenew as AutorenewIcon,
  Done as DoneIcon,
} from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import {
  alpha,
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { UrgentAction } from './UrgentAction';
import { UrgentActionEditor } from './UrgentActionEditor';
import { UrgentActionUpdatePopover } from './UrgentActionUpdatePopover';

export function UrgentActions({ groupCodes, onComplete }) {
  const {
    data: urgentActions,
    isFetching,
    isLoading,
    refetch,
  } = useUrgentActions(groupCodes);
  const { width, ref } = useResizeDetector();
  const columns = useMemo(() => Math.floor(width / 408) || 1, [width]);
  const [editCode, setEditCode] = useState(null);
  const [updateAnchorEl, setUpdateAnchorEl] = useState(null);
  const [locationOccurence, setLocationOccurrence] = useState(null);
  const { mutate: addUrgentUpdate } = useAddUrgentActionUpdate();

  function handleSettingsClick(code) {
    return function () {
      setEditCode(code);
    };
  }

  function handleRefreshClick() {
    refetch();
  }

  function handleAddUpdateClick(event) {
    setUpdateAnchorEl(event.currentTarget);
  }

  function handleAddClick() {
    setEditCode('new');
  }

  function onUrgentActionEditorClose() {
    setEditCode(null);
  }

  function onUpdateEditorClose(message) {
    if (message) {
      addUrgentUpdate({ code: updateAnchorEl.dataset.code, message });
    }

    setUpdateAnchorEl(null);
  }

  function handleShowLocationClick(occurrence) {
    return function () {
      setLocationOccurrence(occurrence);
    };
  }

  function handleLocationMapClose() {
    setLocationOccurrence(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Box sx={{ px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={(theme) => ({
          alignItems: 'center',
          position: 'sticky',
          top: 48,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          zIndex: 700,
          pl: 1,
          py: 1,
        })}
      >
        <Typography variant="h6">Urgent Actions</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Tooltip title="Refresh" placement="bottom">
            <Box component="span">
              <IconButton
                onClick={handleRefreshClick}
                disabled={isFetching || isLoading}
              >
                <AutorenewIcon
                  fontSize="inherit"
                  sx={
                    isLoading || isFetching
                      ? { animation: `${spin} 2s linear infinite` }
                      : undefined
                  }
                />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="Add" placement="left">
            <IconButton size="small" onClick={handleAddClick}>
              <Avatar
                sx={{
                  color: 'secondary.contrastText',
                  bgcolor: 'secondary.main',
                  width: 32,
                  height: 32,
                  fontSize: 24,
                }}
              >
                <AddIcon fontSize="inherit" />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Masonry spacing={1} ref={ref} columns={columns}>
        {urgentActions.map((urgentAction) => (
          <UrgentAction
            key={urgentAction.code}
            urgentAction={urgentAction}
            onSettingsClick={handleSettingsClick(urgentAction.code)}
            onAddUpdateClick={handleAddUpdateClick}
            onShowLocationClick={handleShowLocationClick}
          />
        ))}
      </Masonry>
      <UrgentActionEditor
        code={editCode}
        isOpen={Boolean(editCode)}
        onClose={onUrgentActionEditorClose}
        groupCodes={groupCodes}
      />
      <UrgentActionUpdatePopover
        isOpen={Boolean(updateAnchorEl)}
        anchorEl={updateAnchorEl}
        onClose={onUpdateEditorClose}
      />
      {locationOccurence && (
        <LocationMapDialog
          point={locationOccurence.point}
          addresses={locationOccurence.addresses}
          title={locationOccurence.id}
          description={locationOccurence.summary}
          isOpen={Boolean(locationOccurence)}
          onClose={handleLocationMapClose}
        />
      )}
    </Box>
  );
}
