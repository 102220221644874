import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useLastContactOptions(categoryTypes = []) {
  const queryKey = ['lastContactOptions', categoryTypes];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const telematicsBoxRequest = api
        .post('pipeline/telematicsBoxes', {
          json: [
            {
              $group: {
                _id: null,
                imei: { $addToSet: '$imei' },
              },
            },
            {
              $project: {
                _id: false,
                imei: { $sortArray: { input: '$imei', sortBy: 1 } },
              },
            },
          ],
          signal,
        })
        .json()
        .then((response) => response[0]);

      const vehicleRequest = api
        .post('pipeline/vehicles', {
          json: [
            ...categoryTypes.map((value) => ({
              $unwind: {
                path: `$groups.${value}`,
                preserveNullAndEmptyArrays: true,
              },
            })),
            {
              $group: {
                _id: null,
                imei: { $addToSet: '$telematicsBoxImei' },
                fleetNumber: { $addToSet: '$fleetNumber' },
                registrationNumber: { $addToSet: '$registrationNumber' },
                ...categoryTypes.reduce(
                  (acc, value) => ({
                    ...acc,
                    [value]: { $addToSet: `$groups.${value}` },
                  }),
                  {},
                ),
              },
            },
            {
              $project: {
                _id: false,
                imei: { $sortArray: { input: '$imei', sortBy: 1 } },
                fleetNumber: {
                  $sortArray: { input: '$fleetNumber', sortBy: 1 },
                },
                registrationNumber: {
                  $sortArray: { input: '$registrationNumber', sortBy: 1 },
                },
                ...categoryTypes.reduce(
                  (acc, value) => ({
                    ...acc,
                    [value]: {
                      $sortArray: { input: `$${value}`, sortBy: 1 },
                    },
                  }),
                  {},
                ),
              },
            },
          ],
          signal,
        })
        .json()
        .then((response) => response[0]);

      const [telematicsBoxOptions, vehicleOptions] = await Promise.all([
        telematicsBoxRequest,
        vehicleRequest,
      ]);

      return {
        imei:
          telematicsBoxOptions.imei
            ?.filter((imei) => !!imei)
            .map((imei) => ({
              label: imei,
              value: imei,
            })) ?? [],
        fleetNumber:
          vehicleOptions.fleetNumber
            ?.filter((fleetNumber) => !!fleetNumber)
            .map((fleetNumber) => ({
              label: fleetNumber,
              value: fleetNumber,
            })) ?? [],
        registrationNumber:
          vehicleOptions.registrationNumber
            ?.filter((registrationNumber) => !!registrationNumber)
            .map((registrationNumber) => ({
              label: registrationNumber,
              value: registrationNumber,
            })) ?? [],
        ...categoryTypes.reduce(
          (acc, value) => ({
            ...acc,
            [value]:
              vehicleOptions[value]
                ?.filter((group) => !!group)
                .map((group) => ({
                  label: group,
                  value: group,
                })) ?? [],
          }),
          {},
        ),
      };
    },
    placeholderData: (previousData) =>
      previousData ?? {
        imei: [],
        fleetNumber: [],
        registrationNumber: [],
        ...categoryTypes.reduce(
          (acc, value) => ({
            ...acc,
            [value]: [],
          }),
          {},
        ),
      },
    staleTime: 1000 * 60 * 60 * 24,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
