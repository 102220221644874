import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useConfigurationState(imei, name) {
  const queryKey = ['configurationState', imei, name];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      log('Read', 'Configuration State', { imei, name });

      return api.get(`telematicsBox/${imei}/${name}`, { signal }).json();
    },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
