// import { LocationMapDialog } from '@/components/controls';
import { LocationMapDialog } from '@/components/controls';
import { useNichePeople, useNichePerson } from '@/hooks';
import { getGroupType } from '@/utils';
import { Done as DoneIcon } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import {
  alpha,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { MissingPerson } from './MissingPerson';
import { SharedMissingPersonEditor } from './SharedMissingPersonEditor';
import { useSharedMissingPersons } from './useSharedMissingPersons';

export function MissingPersons({ groupCodes, onComplete }) {
  const { data: missingPersons } = useNichePeople({
    classificatonCodes: ['MIS'],
    groupType: getGroupType('SECTOR'),
    groupCodes,
  });
  const { data: highRiskMissingPersons } = useNichePeople({
    classificatonCodes: ['MIS'],
    groupType: getGroupType('BASIC_COMMAND_UNIT'),
    groupCodes,
    risk: 'HI',
  });
  const { data: sharedMissingPersons } = useSharedMissingPersons(groupCodes);
  const sectorMissingPersons = useMemo(
    () =>
      (missingPersons ?? [])
        .filter(
          ({ id }) =>
            !(highRiskMissingPersons ?? []).some(
              ({ id: highRiskId }) => highRiskId === id,
            ),
        )
        .filter(
          ({ id }) =>
            !(sharedMissingPersons ?? []).some(
              ({ personId }) => personId === id,
            ),
        ),
    [missingPersons, highRiskMissingPersons, sharedMissingPersons],
  );
  const { width, ref } = useResizeDetector();
  const highRiskColumns = useMemo(() => Math.floor(width / 328) || 1, [width]);
  const lowRiskColumns = useMemo(() => Math.floor(width / 248) || 1, [width]);
  const [shareIds, setShareIds] = useState(null);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const { data: selectedMissingPerson } = useNichePerson(selectedPersonId, {
    image: true,
    addresses: true,
    type: 'missing',
  });

  const handleShowMapClick = (id) => () => {
    setSelectedPersonId(id);
  };

  function handleMapClose() {
    setSelectedPersonId(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  const handleShareClick = (code, personId) => () => {
    setShareIds({ code, personId });
  };

  function handleEditorClose() {
    setShareIds(null);
  }

  return (
    <Box sx={{ px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={(theme) => ({
          alignItems: 'center',
          position: 'sticky',
          top: 48,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          zIndex: 700,
          pl: 1,
          py: 1,
        })}
      >
        <Typography variant="h6">Missing Persons</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }} ref={ref}>
        {highRiskMissingPersons?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              High Risk
            </Typography>
            <Masonry spacing={1} columns={highRiskColumns}>
              {highRiskMissingPersons.map(({ id }) => (
                <MissingPerson
                  key={id}
                  personId={id}
                  onShowMapClick={handleShowMapClick(id)}
                  onShareClick={handleShareClick}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
        {sharedMissingPersons?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              Shared
            </Typography>
            <Masonry spacing={1} columns={lowRiskColumns}>
              {sharedMissingPersons.map(({ personId, reason }) => (
                <MissingPerson
                  key={personId}
                  personId={personId}
                  onShowMapClick={handleShowMapClick(personId)}
                  onShareClick={handleShareClick}
                  reason={reason}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
        {sectorMissingPersons?.length > 0 && (
          <Fragment>
            <Typography variant="subtitle1" sx={{ pl: 1 }}>
              Local
            </Typography>
            <Masonry spacing={1} columns={lowRiskColumns}>
              {sectorMissingPersons.map(({ id }) => (
                <MissingPerson
                  key={id}
                  personId={id}
                  onShowMapClick={handleShowMapClick(id)}
                  onShareClick={handleShareClick}
                />
              ))}
            </Masonry>
          </Fragment>
        )}
      </Stack>
      <SharedMissingPersonEditor
        ids={shareIds}
        isOpen={Boolean(shareIds)}
        onClose={handleEditorClose}
      />
      <LocationMapDialog
        point={selectedMissingPerson?.addresses?.[0].point}
        title={`${selectedMissingPerson?.forenames} ${selectedMissingPerson?.surname} missing from`}
        description={selectedMissingPerson?.addresses?.[0]?.label}
        isOpen={Boolean(selectedPersonId)}
        onClose={handleMapClose}
      />
    </Box>
  );
}
