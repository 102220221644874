import { formatGroupBy, round, shortHumanizer } from '@/utils';
import {
  green,
  lightBlue,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { atom } from 'jotai';

export const stateAtom = atom({
  groupBy: 'all',
  sorting: [{ id: 'group', desc: false }],
  unit: 'hours',
  hours: [...Array(24).keys()],
  days: [...Array(7).keys()],
  query: {},
  parameters: {},
});

export const staticGroupByOptions = {
  all: { label: 'All', value: null },
  date: { label: 'Date', value: '$time' },
  month: {
    label: 'Month',
    value: {
      $dateTrunc: {
        date: '$time',
        unit: 'month',
        timezone: 'Europe/London',
      },
    },
  },
  registrationNumber: {
    label: 'Registration Number',
    value: '$vehicle.registrationNumber',
  },
  fleetNumber: { label: 'Fleet Number', value: '$vehicle.fleetNumber' },
  role: { label: 'Role', value: '$vehicle.role' },
  type: { label: 'Type', value: '$vehicle.type' },
  homeStation: { label: 'Home Station', value: '$vehicle.homeStation' },
};

const getMeasureCell =
  (unit) =>
  ({ cell }) =>
    unit === 'hours'
      ? shortHumanizer(cell.getValue() * 3600000)
      : formatPercentage(cell.getValue());

export const columnsFn = (groupBy, unit) => [
  {
    header: staticGroupByOptions[groupBy]?.label ?? 'Group',
    accessorKey: 'group',
    Cell: ({ cell }) => formatGroupBy(groupBy)(cell.getValue()),
  },
  {
    header: 'Vehicle Count',
    accessorKey: 'resourceCount',
  },
  {
    header: 'Moving',
    accessorKey: 'movingHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Stopped @ Home Base',
    accessorKey: 'stoppedHomeBaseHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Stopped @ Other Base',
    accessorKey: 'stoppedOtherBaseHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Stopped @ Workshop',
    accessorKey: 'stoppedWorkshopHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Stopped Elsewhere',
    accessorKey: 'stoppedElsewhereHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Idle @ Home Base',
    accessorKey: 'idleHomeBaseHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Idle @ Other Base',
    accessorKey: 'idleOtherBaseHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Idle @ Workshop',
    accessorKey: 'idleWorkshopHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Idle Elsewhere',
    accessorKey: 'idleElsewhereHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Unaccountable',
    accessorKey: 'unaccountableHours',
    Cell: getMeasureCell(unit),
  },
  {
    header: 'Total Miles',
    accessorKey: 'totalMiles',
  },
  {
    header: 'Average Miles',
    accessorKey: 'averageMiles',
  },
  {
    header: 'Daily Miles',
    accessorKey: 'dailyMiles',
  },
  {
    header: 'Total Trips',
    accessorKey: 'totalTrips',
  },
  {
    header: 'Average Trips',
    accessorKey: 'averageTrips',
  },
  {
    header: 'Daily Trips',
    accessorKey: 'dailyTrips',
  },
];

export const bars = [
  {
    name: 'stoppedHomeBaseHours',
    colour: red[800],
    label: 'Stopped @ Home Base',
  },
  {
    name: 'stoppedOtherBaseHours',
    colour: red[500],
    label: 'Stopped @ Other Base',
  },
  { name: 'idleHomeBaseHours', colour: purple[700], label: 'Idle @ Home Base' },
  {
    name: 'idleOtherBaseHours',
    colour: purple[300],
    label: 'Idle @ Other Base',
  },
  {
    name: 'stoppedWorkshopHours',
    colour: orange[800],
    label: 'Stopped @ Workshop',
  },
  { name: 'idleWorkshopHours', colour: orange[500], label: 'Idle @ Workshop' },
  {
    name: 'stoppedElsewhereHours',
    colour: teal[800],
    label: 'Stopped Elsewhere',
  },
  { name: 'idleElsewhereHours', colour: teal[500], label: 'Idle Elsewhere' },
  { name: 'movingHours', colour: green[500], label: 'Moving' },
  {
    name: 'unaccountableHours',
    colour: lightBlue[400],
    label: 'Unaccountable',
  },
];

export function convertToPercentages(record) {
  const values = Object.entries(record).filter(([key]) =>
    key.endsWith('Hours'),
  );
  const totalHours = values.reduce((acc, [, value]) => acc + value, 0);

  const valuesAsPercentage = values.reduce((acc, [key, value]) => {
    acc[key] = round((value / totalHours) * 100, 2);
    return acc;
  }, {});

  return { ...record, ...valuesAsPercentage };
}

export function formatPercentage(value) {
  return `${value}%`;
}
