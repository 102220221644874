import { get, idProperties } from '@/utils';
import { liveOptions } from '@/utils/config';
import { LocationSearching as FollowIcon } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { Fragment } from 'react';
import { ItemAvatar } from './ItemAvatar';
import { OverflowTip } from './OverflowTip';
import { getIfNotAlreadyShown } from './incidentUtility';

const {
  labelOverridePaths,
  showOverriddenLabelInListItems: showOverridden,
  leftOfFirstLineOfListItemsPaths,
  leftOfSecondLineOfListItemsPaths,
  rightOfFirstLineOfListItemsPaths,
  rightOfSecondLineOfListItemsPaths,
} = liveOptions;

export function LiveListItem({
  item,
  type,
  style,
  highlighted,
  onHoverItem,
  onClick,
  onFollowToggle,
  followedIdsByType,
  hideFollow,
  primaryPath,
  secondaryPath,
  tertiaryPath,
  secondaryComponent = undefined,
  detailComponent = undefined,
  shownPaths = [],
  divider = false,
  ignoreOverride,
}) {
  if (!item) {
    return <Fragment />;
  }

  const idPath = idProperties[type];
  const id = item.id || (idPath && get(item, idPath));

  const idType = { id, type };
  const handleHoverEnter = onHoverItem ? () => onHoverItem(idType) : undefined;
  const handleHoverLeave = onHoverItem ? () => onHoverItem({}) : undefined;
  const handleClick = onClick
    ? () => onClick({ ...item, id, type })
    : undefined;
  const following = followedIdsByType?.[type]?.[id];

  const primaryText = get(item, primaryPath);
  const labelOverridePath = labelOverridePaths?.[type];
  const primaryOverride = ignoreOverride
    ? undefined
    : get(item, labelOverridePath);

  // we can have items right aligned for the first and second lines
  const leftPrimaryPath =
    leftOfFirstLineOfListItemsPaths?.[type] ?? primaryPath;
  const rightPrimaryPath = rightOfFirstLineOfListItemsPaths?.[type];

  const leftSecondaryPath =
    leftOfSecondLineOfListItemsPaths?.[type] ?? secondaryPath;
  const rightSecondaryPath = rightOfSecondLineOfListItemsPaths?.[type];

  function displayValue(path) {
    const value = get(item, path);

    if ((value?.length ?? 0) === 0) {
      return undefined;
    }

    if (Array.isArray(value)) {
      if (value.length === 1) {
        return <OverflowTip title={value[0]}>{value[0]}</OverflowTip>;
      } else {
        return (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <OverflowTip title={value[0]}>{value[0]}</OverflowTip>
            <Tooltip
              title={
                <table>
                  <tbody>
                    {value.slice(1).map((value, index) => (
                      <tr key={index}>
                        <td align="left">{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            >
              <Chip
                label={`+${value.length - 1}`}
                style={{ marginLeft: 4, fontSize: 13, height: 18 }}
                size="small"
              ></Chip>
            </Tooltip>
          </Box>
        );
      }
    } else {
      return <OverflowTip title={value}>{value}</OverflowTip>;
    }
  }

  // a third line in the list item will show the default label (e.g. fleet # or person
  // name) if there is a primary override (e.g. to show call sign) otherwise it will show
  // whatever's in the tertiaryPath (which is usually set when showSortFieldInListItems
  // is true meaning if you're sorting by X show X in the item unless it's already shown)
  let tertiaryValue =
    primaryOverride && showOverridden
      ? primaryText
      : getIfNotAlreadyShown(
          item,
          tertiaryPath,
          shownPaths.concat([
            primaryPath,
            secondaryPath,
            rightPrimaryPath,
            rightSecondaryPath,
          ]),
        );

  // if the value is a date, format it for local time - do simple fast tests first for iso
  if (tertiaryValue?.length === 24 && tertiaryValue[23] === 'Z') {
    const date = new Date(tertiaryValue);
    if (isValid(date)) {
      tertiaryValue = format(date, 'dd/MM/yyyy HH:mm:ss');
    }
  }

  function displayLine(leftPath, rightPath) {
    const leftValue = displayValue(leftPath);
    const rightValue = displayValue(rightPath);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxHeight: 18,
          }}
        >
          {leftValue}
        </Box>
        {rightValue && <Box style={{ flexGrow: 1 }} />}
        {rightValue && (
          <Box
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'right',
              ml: 0.5,
              maxHeight: 18,
            }}
          >
            {rightValue}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <ListItemButton
      selected={highlighted}
      style={style}
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      divider={divider}
    >
      <ListItemAvatar>
        <ItemAvatar item={item} type={type} />
      </ListItemAvatar>
      <ListItemText
        component="div"
        primary={
          <Fragment>{displayLine(leftPrimaryPath, rightPrimaryPath)}</Fragment>
        }
        slotProps={{
          secondary: {
            component: 'span',
            noWrap: true,
            style: { lineHeight: 1.3 },
          },
        }}
        secondary={
          secondaryComponent || (
            <Fragment>
              {displayLine(leftSecondaryPath, rightSecondaryPath)}
              <Tooltip title={tertiaryValue}>
                <Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flex: 1,
                    fontSize: 12,
                  }}
                >
                  {tertiaryValue}
                </Typography>
              </Tooltip>
            </Fragment>
          )
        }
      />
      {/* for whatever reason the ListItemSecondaryAction doesn't play well with 
          virtualized lists, positioning gets screwed up, so instead I'll
          use a normal button but stop propagation of the click to the select
          below
        */}
      {/* The detail component is optional and can be added to display extra info about item next to follow icon */}
      {detailComponent || <Fragment />}
      {!hideFollow && (
        <Tooltip title={following ? 'Stop following' : 'Follow'}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onFollowToggle(type, id);
            }}
            size="large"
          >
            <FollowIcon
              fontSize="small"
              color={following ? 'primary' : 'disabled'}
            />
          </IconButton>
        </Tooltip>
      )}
    </ListItemButton>
  );
}
