import { ConfirmationDialog } from '@/components/dialogs';
import { useUpsertUserQuery, useUserInfo } from '@/hooks';
import { downloadData, getFilenameForDownload } from '@/utils';
import {
  CreateNewFolder as CreateNewFolderIcon,
  Download as DownloadIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  formatDuration,
  interval,
  intervalToDuration,
  isValid,
} from 'date-fns';
import { FolderOpen as FolderOpenIcon } from 'mdi-material-ui';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useRef, useState } from 'react';
import { DatePicker, DateTimePicker } from '..';
import { FetchButton } from '../FetchButton';
import { Field } from './Field';
import { OpenDialog } from './OpenDialog';
import { SaveDialog } from './SaveDialog';
import { SelectFilterField } from './SelectFilterField';
import { useResourceOptions } from './useResourceOptions';
import { createQuery } from './utils';

export function Parameters({
  onFetch,
  onCancel,
  isFetching,
  dateOnly,
  pointEvent,
  hideTimePeriod,
  collection,
  pipelineFn,
  columns,
  value: userQuery,
  onChange,
  className,
  style,
  sx,
  options: externalOptions,
  eventFilters = [],
  children,
  ...visibleFilters
}) {
  const { mutate: upsertUserQuery } = useUpsertUserQuery();
  const [openDialog, setOpenDialog] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const timeHeaderRef = useRef(null);
  const { data: resourceOptions, isSuccess } = useResourceOptions();
  const options = externalOptions ? externalOptions : resourceOptions;
  const { data: userInfo } = useUserInfo();
  const { value = {} } = userQuery ?? {};
  const Picker = dateOnly ? DatePicker : DateTimePicker;

  function handleFetchClick(event) {
    const startError = hideTimePeriod
      ? null
      : value.startTime
        ? errors.startTime
        : 'Required';
    const endError = hideTimePeriod
      ? null
      : value.endTime
        ? errors.endTime
        : 'Required';

    setErrors({
      ...errors,
      startTime: startError,
      endTime: endError,
    });

    if (!!startError || !!endError) {
      timeHeaderRef.current.scrollIntoView();
    } else {
      if (isFetching) {
        onCancel(event);
      } else {
        const query = createQuery(
          value,
          hideTimePeriod,
          pointEvent,
          visibleFilters,
          options,
          eventFilters,
        );

        onFetch(event, query);
      }
    }
  }

  function getErrorDescription(reason) {
    switch (reason) {
      case 'maxDate':
        return 'After end';
      case 'minDate':
        return 'Before start';
      case 'disableFuture':
        return 'Future';
      case 'invalidDate':
        return 'Invalid';
      default:
        return null;
    }
  }

  const handleChange = (name) => (newValue) => {
    onChange({ ...userQuery, value: { ...value, [name]: newValue } });
  };

  function handleStartTimeChange(startTime) {
    if (isValid(startTime)) {
      setErrors({ ...errors, startTime: null });
    }

    onChange({
      ...userQuery,
      value: {
        ...value,
        startTime,
      },
    });
  }

  function handleStartTimeError(reason, startTime) {
    setErrors({
      ...errors,
      startTime: startTime ? getErrorDescription(reason) : null,
    });
  }

  function handleEndTimeChange(endTime) {
    if (isValid(endTime)) {
      setErrors({ ...errors, endTime: null });
    }

    onChange({
      ...userQuery,
      value: {
        ...value,
        endTime,
      },
    });
  }

  function handleEndTimeError(reason, endTime) {
    setErrors({
      ...errors,
      endTime: endTime ? getErrorDescription(reason) : null,
    });
  }

  function handleDownloadClick() {
    const filename = getFilenameForDownload(
      collection,
      'csv',
      value.startTime,
      value.endTime,
    );

    const query = createQuery(
      value,
      hideTimePeriod,
      pointEvent,
      visibleFilters,
      options,
      eventFilters,
    );

    setIsDownloading(true);

    downloadData(collection, filename, pipelineFn(query), columns)
      .catch((error) => {
        captureException(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setIsDownloading(false));
  }

  const handleOpenDialog = (type) => () => {
    setOpenDialog(type);
  };

  function handleNewOk() {
    setOpenDialog(null);
    onChange({});
    setErrors({});
  }

  function handleNewCancel() {
    setOpenDialog(null);
  }

  function handleOpenDialogClose(openedQuery) {
    setOpenDialog(null);

    if (openedQuery) {
      setErrors({});
      onChange(openedQuery);
    }
  }

  function handleSaveDialogClose(values) {
    setOpenDialog(null);

    if (values) {
      const pipeline =
        pipelineFn?.(
          createQuery(
            { ...value, startTime: '##START_TIME##', endTime: '##END_TIME##' },
            hideTimePeriod,
            pointEvent,
            visibleFilters,
            options,
            eventFilters,
          ),
        ) ?? [];

      upsertUserQuery(
        {
          ...userQuery,
          title: values.title,
          collection,
          interval: values.interval,
          value,
          pipeline,
          columns: columns ?? [],
        },
        {
          onSuccess: (savedQuery) => {
            onChange(savedQuery);
          },
        },
      );
    }
  }

  return (
    <Box className={className} style={style} sx={sx}>
      <Stack sx={{ px: 1, height: 1 }}>
        <Stack
          spacing={1}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: 1,
            pb: 1,
          }}
        >
          {children}
          {!hideTimePeriod && (
            <Fragment>
              <Stack
                direction="row"
                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  ref={timeHeaderRef}
                >
                  Time Period
                </Typography>
                {value.startTime &&
                  value.endTime &&
                  !isNaN(value.startTime.getTime()) &&
                  !isNaN(value.endTime.getTime()) && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      ref={timeHeaderRef}
                    >
                      {formatDuration(
                        intervalToDuration(
                          interval(value.startTime, value.endTime),
                        ),
                      ) || '0 seconds'}
                    </Typography>
                  )}
              </Stack>
              <Picker
                label="Start"
                value={value.startTime ?? ''}
                onChange={handleStartTimeChange}
                onError={handleStartTimeError}
                clearable
                maxDate={value.endTime ?? new Date('2100-01-01')}
                error={!!errors.startTime}
                helperText={errors.startTime}
                disableFuture
              />
              <Picker
                label="End"
                value={value.endTime ?? ''}
                onChange={handleEndTimeChange}
                onError={handleEndTimeError}
                clearable
                minDate={value.startTime ?? new Date('1900-01-01')}
                disableFuture
                error={!!errors.endTime}
                helperText={errors.endTime}
              />
            </Fragment>
          )}
          {isSuccess &&
            Object.entries(options)
              .filter(([type]) => visibleFilters[type])
              .map(([type, { label, fields }]) => (
                <Fragment key={type}>
                  <Divider />
                  <Typography variant="subtitle2" color="textSecondary">
                    {label}
                  </Typography>
                  {Object.entries(fields).map(([name, { label, values }]) => (
                    <SelectFilterField
                      key={name}
                      label={label}
                      value={value[`${type}.${name}`]}
                      onChange={handleChange(`${type}.${name}`)}
                      multiple
                      options={values}
                      size="small"
                    />
                  ))}
                </Fragment>
              ))}
          {eventFilters?.length > 0 && (
            <Fragment>
              <Divider />
              <Typography variant="subtitle2" color="textSecondary">
                Event
              </Typography>
              <Stack spacing={1}>
                {eventFilters.map(({ name, ...props }) => (
                  <Field
                    key={name}
                    value={value[name] ?? null}
                    onChange={handleChange(name)}
                    size="small"
                    {...props}
                  />
                ))}
              </Stack>
            </Fragment>
          )}
        </Stack>
        <Divider />
        <Stack spacing={0.5} direction="row" sx={{ py: 1 }}>
          <Tooltip title="New">
            <Button
              variant="outlined"
              sx={{ p: 0.75, minWidth: 32 }}
              onClick={handleOpenDialog('new')}
            >
              <CreateNewFolderIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Open">
            <Button
              variant="outlined"
              sx={{ p: 0.75, minWidth: 32 }}
              onClick={handleOpenDialog('open')}
            >
              <FolderOpenIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Save">
            <Button
              variant="outlined"
              sx={{ p: 0.75, minWidth: 32 }}
              onClick={handleOpenDialog('save')}
            >
              <SaveIcon fontSize="small" />
            </Button>
          </Tooltip>
          {pipelineFn && columns && (
            <Tooltip title={isDownloading ? 'Downloading' : 'Download'}>
              <Box sx={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  sx={{ p: 0.75, minWidth: 32 }}
                  onClick={handleDownloadClick}
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <DownloadIcon fontSize="small" />
                  )}
                </Button>
              </Box>
            </Tooltip>
          )}
          <FetchButton
            isFetching={isFetching}
            onClick={handleFetchClick}
            sx={{ flex: 1 }}
          />
        </Stack>
      </Stack>
      <ConfirmationDialog
        title="New Parameters"
        text="Your existing parameters will be cleared. Are you sure?"
        okButtonText="yes"
        cancelButtonText="no"
        open={openDialog === 'new'}
        onOk={handleNewOk}
        onCancel={handleNewCancel}
      />
      <OpenDialog
        key={[collection, userInfo?.username]}
        open={openDialog === 'open'}
        onClose={handleOpenDialogClose}
        collection={collection}
        username={userInfo?.username}
      />
      <SaveDialog
        open={openDialog === 'save'}
        onClose={handleSaveDialogClose}
        collection={collection}
        value={userQuery}
        enableInterval={pipelineFn && columns}
        username={userInfo?.username}
      />
    </Box>
  );
}
