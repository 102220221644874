import { api } from '@/apis';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

interface LocationType {
  value: string;
  label: string;
  values: { value: string; label: string }[];
  header: string;
  isBoundary: boolean;
  isHomeStation: boolean;
  d: string;
}

interface LocationTypes {
  [key: string]: LocationType;
}

export function useLocationTypes(): UseQueryResult<LocationTypes> & {
  cancel: () => void;
} {
  const queryKey = ['locationTypes'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        { $match: { name: 'locationType' } },
        {
          $project: {
            _id: false,
            value: true,
            label: true,
            values: true,
            header: true,
            path: true,
            isBoundary: true,
            isHomeStation: true,
            d: true,
          },
        },
      ];

      const types: Array<{ path: string } & LocationType> = await api
        .post('pipeline/options', { json, signal })
        .json();

      return types.reduce(
        (
          acc: LocationTypes,
          { path, ...type }: { path: string } & LocationType,
        ) => ({
          ...acc,
          [path]: type,
        }),
        {},
      );
    },
    placeholderData: (previousData) => previousData ?? {},
    staleTime: 1000 * 60 * 60 * 24,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
