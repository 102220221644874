import { red } from '@mui/material/colors';
import { Layer, Source } from 'react-map-gl/maplibre';

export function EventsSource({ id, data, visibility }) {
  return (
    <Source id={id} type="geojson" data={data} tolerance={0}>
      <Layer
        id={`${id}-path-layer`}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
          visibility,
        }}
        paint={{
          'line-color': '#000',
          'line-width': 2,
        }}
      />
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-triangle',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.1, 12, 0.8],
          visibility,
        }}
        paint={{
          'icon-color': '#fff',
          'icon-halo-color': red.A700,
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-accelerometer',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.075, 12, 0.6],
          visibility,
        }}
        paint={{
          'icon-color': '#000',
        }}
      />
    </Source>
  );
}
