import { useOptionLookup } from '@/hooks';
import { Settings as SettingsIcon } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { amber, brown } from '@mui/material/colors';
import { Link } from 'react-router';

export function BriefCover({ brief }) {
  const typeOptions = useOptionLookup('groupType');

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark' ? brown[500] : amber[100],
        textAlign: 'center',
        width: 180,
        elevation: 6,
      })}
    >
      <CardActionArea component={Link} to={`./${brief.code}?page=0`}>
        <CardContent
          sx={{
            height: 176,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">{brief.title}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'auto',
            }}
          >
            <Stack
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              {brief?.groups?.map((group) => (
                <Chip
                  key={group.code}
                  label={group.name}
                  icon={
                    <Chip
                      label={typeOptions[group.type]}
                      size="small"
                      sx={{ maxWidth: 80 }}
                    />
                  }
                  sx={{ maxWidth: 160 }}
                  // sx={{ bgcolor: 'background.paper' }}
                />
              ))}
            </Stack>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
        <Tooltip title="Settings">
          <IconButton
            aria-label="settings"
            size="small"
            component={Link}
            to={`?configure=${brief.code}`}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
