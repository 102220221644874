import { DateTimePicker, FilterAutocomplete } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadData, getFilenameForDownload, spin } from '@/utils';
import {
  Autorenew as AutorenewIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { AuditParameters } from './AuditParameters';
import { useAuditOptions } from './useAuditOptions';
import { useAudits } from './useAudits';
import { useAuditTotals } from './useAuditTotals';
import {
  columns,
  csvColumns,
  downloadPipelineFn,
  getErrorDescription,
  stateAtom,
} from './utils';

export function Audit() {
  useDocumentTitle('IR3 | Audit');
  const [
    {
      startTime,
      endTime,
      users,
      dataTypes,
      actions,
      sorting,
      pagination,
      errors,
    },
    setState,
  ] = useAtom(stateAtom);
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    data,
    isFetching: dataFetching,
    isLoading: dataLoading,
    refetch,
    cancel,
  } = useAudits(
    startTime,
    endTime,
    users,
    dataTypes,
    actions,
    sorting,
    pagination,
  );
  const { data: options } = useAuditOptions();
  const {
    data: aggregated,
    isFetching: totalsFetching,
    isLoading: totalsLoading,
    refetch: refetchTotals,
    cancel: cancelTotals,
  } = useAuditTotals(startTime, endTime, users, dataTypes, actions);
  const isLoading =
    dataFetching || totalsFetching || dataLoading || totalsLoading;

  function handleRefetchClick() {
    const startError = startTime ? errors.startTime : 'Required';
    const endError = endTime ? errors.endTime : 'Required';

    setState((state) => ({
      ...state,
      errors: { ...state.errors, startTime: startError, endTime: endError },
    }));

    if (isLoading) {
      cancel();
      cancelTotals();
    } else {
      if (!startError && !endError) {
        refetch();
        refetchTotals();
      }
    }
  }

  function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'audits',
      'csv',
      startTime,
      endTime,
    );

    setIsDownloading(true);

    downloadData(
      'audits',
      filename,
      downloadPipelineFn(startTime, endTime, users, dataTypes, actions),
      csvColumns,
    )
      .catch((error) => {
        captureException(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setIsDownloading(false));
  }

  const handleTimeChange = (name) => (value) => {
    setState((state) => ({
      ...state,
      [name]: value,
      errors: {
        ...state.errors,
        [name]: null,
      },
    }));
  };

  const handleTimeError = (name) => (reason, value) => {
    setState((state) => ({
      ...state,
      errors: {
        ...state.errors,
        [name]: value ? getErrorDescription(reason) : null,
      },
    }));
  };

  const handleChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  function renderDetailPanel(cell) {
    return cell.row.original.parameters ? (
      <AuditParameters parameters={cell.row.original.parameters} />
    ) : null;
  }

  const table = useMaterialReactTable({
    columns,
    data,
    state: {
      density: 'compact',
      sorting,
      pagination,
      isLoading,
    },
    defaultColumn: { size: 0, enableEditing: false },
    getRowId: (row) => row.identifier,
    rowCount: aggregated[0]?.total ?? 0,
    onPaginationChange: handleTableStateChange('pagination'),
    onSortingChange: handleTableStateChange('sorting'),
    renderDetailPanel,
    enableExpandAll: false,
    positionToolbarAlertBanner: 'none',
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTablePaperProps: { elevation: 0, variant: 'elevation' },
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 280px)',
        maxHeight: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
  });

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          pt: 1,
        }}
      >
        <Stack sx={{ px: 1, height: 1 }}>
          <Stack
            spacing={1}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              flex: 1,
              py: 1,
            }}
          >
            <DateTimePicker
              label="Start"
              value={startTime ?? ''}
              onChange={handleTimeChange('startTime')}
              onError={handleTimeError('startTime')}
              clearable
              maxDate={endTime ?? new Date('2100-01-01')}
              error={!!errors.startTime}
              helperText={errors.startTime}
              disableFuture
            />
            <DateTimePicker
              label="End"
              value={endTime ?? ''}
              onChange={handleTimeChange('endTime')}
              onError={handleTimeError('endTime')}
              clearable
              minDate={startTime ?? new Date('1900-01-01')}
              disableFuture
              error={!!errors.endTime}
              helperText={errors.endTime}
            />
            <Divider />
            <FilterAutocomplete
              label="User"
              value={users}
              onChange={handleChange('users')}
              options={options?.users}
            />
            <FilterAutocomplete
              label="Data Type"
              value={dataTypes}
              onChange={handleChange('dataTypes')}
              options={options?.dataTypes}
            />
            <FilterAutocomplete
              label="Action"
              value={actions}
              onChange={handleChange('actions')}
              options={options?.actions}
            />
          </Stack>
          <Divider />
          <Stack spacing={0.5} direction="row" sx={{ py: 1 }}>
            <Tooltip title={isDownloading ? 'Downloading' : 'Download'}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleDownloadClick}
                endIcon={
                  isDownloading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <DownloadIcon fontSize="small" />
                  )
                }
              >
                {isDownloading ? 'Cancel' : 'Download'}
              </Button>
            </Tooltip>
            <Tooltip title={isDownloading ? 'Loading' : 'Refetch'}>
              <Button
                sx={{ p: 1, minWidth: 36 }}
                variant="contained"
                color={isLoading ? 'error' : 'primary'}
                onClick={handleRefetchClick}
              >
                <AutorenewIcon
                  sx={
                    isLoading
                      ? { animation: `${spin} 2s linear infinite` }
                      : undefined
                  }
                />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
