import { isEmpty, transformNumericValue } from '@/utils';
import { lastContactDefaultDays } from '@/utils/config';
import { Key as KeyIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { amber } from '@mui/material/colors';
import { format, startOfDay, subDays } from 'date-fns';
import { atom } from 'jotai';
import { SignalStrengthIcon } from './SignalStrengthIcon';

export const stateAtom = atom({
  lastContactTime: subDays(startOfDay(new Date()), lastContactDefaultDays),
  imeis: [],
  fleetNumbers: [],
  registrationNumbers: [],
  disposed: 2,
  ignition: 2,
  batteryVoltage: {
    condition: 'equal',
    measure: '',
    modifier: 1,
  },
  signalStrength: {
    condition: 'equal',
    measure: '',
    modifier: 1,
  },
  gpsValidPollPercentage: {
    condition: 'equal',
    measure: '',
    modifier: 1,
  },
  columnVisibility: {},
});

export const pipelineFn = (
  lastContactTime,
  imeis,
  fleetNumbers,
  registrationNumbers,
  categoryTypes = [],
  groups = {},
  disposed = 2,
  ignition = 2,
  batteryVoltage = { condition: 'equal', measure: '' },
  signalStrength = { condition: 'equal', measure: '' },
  gpsValidPollPercentage = { condition: 'equal', measure: '' },
) => [
  { $set: { 'mostRecentPoll.time': { $toDate: '$mostRecentPoll.time' } } },
  {
    $match: {
      'mostRecentPoll.time': { $lt: lastContactTime },
      ...(imeis.length > 0 ? { imei: { $in: imeis } } : {}),
    },
  },
  {
    $lookup: {
      from: 'vehicles',
      localField: 'imei',
      foreignField: 'telematicsBoxImei',
      as: 'vehicle',
      pipeline: [
        {
          $project: {
            _id: false,
            fleetNumber: true,
            registrationNumber: true,
            disposalDate: true,
            groups: categoryTypes.reduce(
              (acc, value) => ({
                ...acc,
                [value]: true,
              }),
              {},
            ),
          },
        },
      ],
    },
  },
  { $unwind: { path: '$vehicle', preserveNullAndEmptyArrays: true } },
  {
    $project: {
      _id: false,
      imei: true,
      mostRecentTime: '$mostRecentPoll.time',
      disposalDate: '$vehicle.disposalDate',
      fleetNumber: '$vehicle.fleetNumber',
      registrationNumber: '$vehicle.registrationNumber',
      groups: '$vehicle.groups',
      ignitionOn: '$mostRecentPoll.ignitionOn',
      batteryVoltage: '$mostRecentPoll.deviceProperties.batteryVoltage',
      signalStrength: '$mostRecentPoll.deviceProperties.deviceSignalStrength',
      gpsValidPollPercentage: {
        $round: [
          {
            $multiply: [
              {
                $divide: [
                  '$gpsValidPollCount.valid',
                  '$gpsValidPollCount.total',
                ],
              },
              100,
            ],
          },
          0,
        ],
      },
      location: { $first: '$mostRecentPoll.locations' },
      lastPosition: '$mostRecentPoll.position',
    },
  },
  {
    $match: {
      ...(disposed !== 2 ? { disposalDate: { $exists: disposed } } : {}),
      ...(ignition !== 2 ? { ignitionOn: { $eq: ignition } } : {}),
      ...(fleetNumbers.length > 0
        ? { fleetNumber: { $in: fleetNumbers } }
        : {}),
      ...(registrationNumbers.length > 0
        ? { registrationNumber: { $in: registrationNumbers } }
        : {}),
      ...(Object.values(groups).every(isEmpty)
        ? {}
        : Object.entries(groups).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [`groups.${key}`]: { $in: value },
            }),
            {},
          )),
      ...(batteryVoltage.measure
        ? { batteryVoltage: transformNumericValue(batteryVoltage) }
        : {}),
      ...(signalStrength.measure
        ? { deviceSignalStrength: transformNumericValue(signalStrength) }
        : {}),
      ...(gpsValidPollPercentage.measure
        ? {
            gpsValidPollPercentage: transformNumericValue(
              gpsValidPollPercentage,
            ),
          }
        : {}),
    },
  },
];

export const columnsFn = (categories = []) => [
  {
    header: 'IMEI',
    accessorKey: 'imei',
    filterVariant: 'multi-select',
  },
  {
    header: 'Last Contact Time',
    accessorKey: 'mostRecentTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Disposal Date',
    accessorKey: 'disposalDate',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy') : '',
  },
  {
    header: 'Fleet Number',
    id: 'fleetNumber',
    accessorFn: ({ fleetNumber }) => fleetNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Registration',
    id: 'registrationNumber',
    accessorFn: ({ registrationNumber }) => registrationNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Ignition',
    accessorKey: 'ignitionOn',
    Cell: ({ cell }) => (
      <KeyIcon sx={{ color: cell.getValue() ? amber[700] : 'text.disabled' }} />
    ),
  },
  {
    header: 'Battery',
    accessorKey: 'batteryVoltage',
    // filterVariant: 'range',
    Cell: ({ cell }) =>
      cell.getValue() ? (
        <Box
          component="span"
          sx={{ color: cell.getValue() < 8.5 ? 'error' : '' }}
        >
          {`${cell.getValue()}v`}
        </Box>
      ) : (
        <Box component="span" sx={{ color: 'warning.main' }}>
          N/A
        </Box>
      ),
  },
  {
    header: 'Signal Strength',
    accessorKey: 'signalStrength',
    Cell: ({ cell }) => (
      <SignalStrengthIcon signalStrength={cell.getValue()} isDecoded={true} />
    ),
  },
  {
    header: 'Valid GPS Rate',
    accessorKey: 'gpsValidPollPercentage',
    Cell: ({ cell }) =>
      cell.getValue() !== null ? (
        <Box
          component="span"
          sx={{ color: cell.getValue() < 60 ? 'error.main' : '' }}
        >
          {`${cell.getValue()}%`}
        </Box>
      ) : (
        <Box component="span" sx={{ color: 'warning.main' }}>
          N/A
        </Box>
      ),
  },
  {
    header: 'Location Type',
    id: 'location.type',
    accessorFn: ({ location: { type } = {} }) => type ?? '',
  },
  {
    header: 'Location Name',
    id: 'location.name',
    accessorFn: ({ location: { name } = {} }) => name ?? '',
  },
  ...categories.map(({ value, label }) => ({
    header: label,
    id: `groups.${value}`,
    accessorFn: ({ groups = {} } = {}) => groups[value]?.join(', ') ?? '',
  })),
];
