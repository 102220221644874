import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useSubject(type, personId, defaultValue = {}) {
  const queryKey = ['subjects', type, personId, defaultValue];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const existingSubject = await briefingApi
        .get(`subjects/${type}/${personId}`, { signal })
        .json();

      if (existingSubject?.code) {
        return existingSubject;
      } else {
        return defaultValue;
      }
    },
    enabled: !!type && !!personId,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
