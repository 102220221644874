import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNicheOccurrence(
  number,
  {
    addresses = false,
    officers = false,
    people = false,
    vehicles = false,
  } = {},
) {
  const queryKey = [
    'occurrences',
    number,
    addresses,
    officers,
    people,
    vehicles,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const searchParams = new URLSearchParams({
        addresses,
        officers,
        people,
        vehicles,
      });

      return briefingApi
        .get(`niche/occurrences/${number}`, { searchParams, signal })
        .json();
    },
    enabled: !!number,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
