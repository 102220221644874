import { api } from '@/apis';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

interface TripClassification {
  value: string;
  label: string;
  applicableSpeedRules?: string[];
  exempt?: boolean;
}

export function useTripClassifications(): UseQueryResult<
  TripClassification[]
> & { cancel: () => void } {
  const queryKey = ['tripClassifications'];
  const queryClient = useQueryClient();
  const query = useQuery<TripClassification[]>({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        { $match: { name: 'tripClassification' } },
        {
          $project: {
            _id: false,
            value: true,
            label: true,
            applicableSpeedRules: true,
            exempt: true,
          },
        },
      ];

      return api.post('pipeline/options', { json, signal }).json();
    },
    placeholderData: (previousData) => previousData ?? [],
    staleTime: 1000 * 60 * 60 * 24,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
