import { Layer, Source } from 'react-map-gl/maplibre';

export function PeopleSource({ id, data, visibility }) {
  return (
    <Source id={id} type="geojson" data={data}>
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-circle',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.125, 12, 1],
          visibility,
        }}
        paint={{
          'icon-color': '#000',
          'icon-halo-color': '#fff',
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'icon-image': ['case', ['has', 'person'], 'ir3-person', 'ir3-radio'],
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.075, 12, 0.6],
          visibility,
        }}
        paint={{
          'icon-color': '#fff',
        }}
      />
    </Source>
  );
}
