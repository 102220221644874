import { api } from '@/apis';
import { log } from '@/utils';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { DriverScoresParams, GroupedDriverScore } from './types';

export function useGroupedDriverScores(
  params: DriverScoresParams,
): UseQueryResult<GroupedDriverScore[]> & { cancel: () => void } {
  const queryKey = ['groupedDriverScores', params];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      log('Read', 'Grouped Driver Scores', params);

      const scores: GroupedDriverScore[] = await api
        .post('groupedDriverScores', { json: params, signal })
        .json();

      return scores.sort(
        (a: GroupedDriverScore, b: GroupedDriverScore) =>
          a.date.getTime() - b.date.getTime(),
      );
    },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
