import { briefingApi } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useWatches(groupCodes = []) {
  const queryKey = ['watches', groupCodes];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      log('Read', 'Watches', { groupCodes });

      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );
      return briefingApi.get('watches', { searchParams, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
