import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNicheNominal(
  nominalId,
  { image = false, addresses = false } = {},
) {
  const queryKey = ['nicheNominal', nominalId, image, addresses];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const personId = await briefingApi
        .get(`niche/people/id/${nominalId}`)
        .text();

      if (!personId) {
        return null;
      }

      const searchParams = new URLSearchParams({
        image,
        addresses,
      });

      return briefingApi
        .get(`niche/people/${personId}`, { searchParams, signal })
        .json();
    },
    enabled: !!nominalId,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
