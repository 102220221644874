import { getStatusColor, getStatusForeColor } from '@/utils';
import { CarCrash as CarCrashIcon } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { format } from 'date-fns';
import { dateTimeSecondsFormat } from '../utils';

export function EventListItem({ item: { size, start }, feature, ...props }) {
  const primary =
    feature.properties.vehicle?.registrationNumber ?? feature.properties.imei;
  const secondary = format(feature.properties.startTime, dateTimeSecondsFormat);

  return (
    <ListItemButton
      {...props}
      dense
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            color: getStatusForeColor('default'),
            bgcolor: getStatusColor('default'),
            borderColor: getStatusForeColor(feature.properties?.status),
            borderWidth: 2,
            borderStyle: 'solid',
          }}
        >
          <CarCrashIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        slotProps={{
          primary: { noWrap: true },
          secondary: { noWrap: true },
        }}
      />
      <Chip
        size="small"
        color="error"
        label={`${feature.properties.maximumForces.absolute}G`}
        sx={{ fontSize: '.6rem', height: 18 }}
      />
    </ListItemButton>
  );
}
