import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

/**
 * @typedef {Object.<string, Array.<string>>} Groups
 * This is an object where each property is an array.
 */

/**
 * @typedef {object} Engagement
 * @property {Date} month - The month of the engagement.
 * @property {string} username - The username of the user.
 * @property {string} [homeStation] - The home station of the user.
 * @property {string} [role] - The role of the user.
 * @property {string[]} [groupAncestorCodes] - The groups of the user.
 * @property {number} count - The number of engagements.
 */

/**
 * A hook that fetches engagement data.
 * @returns {import('@tanstack/react-query').UseQueryResult<Engagement[]>}
 */
export function useEngagements() {
  const queryKey = ['engagements'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        {
          $group: {
            _id: {
              month: {
                $dateTrunc: {
                  date: '$time',
                  unit: 'month',
                  timezone: 'Europe/London',
                },
              },
              user: '$user',
            },
            count: { $sum: 1 },
          },
        },
        {
          $lookup: {
            from: 'people',
            localField: '_id.user',
            foreignField: 'emailAddress',
            pipeline: [
              {
                $graphLookup: {
                  from: 'groups',
                  startWith: '$groupCodes',
                  connectFromField: 'parentCodes',
                  connectToField: 'code',
                  as: 'groupAncestorCodes',
                },
              },
              {
                $project: {
                  _id: 0,
                  homeStation: 1,
                  role: 1,
                  groupAncestorCodes: {
                    $map: {
                      input: '$groupAncestorCodes',
                      as: 'group',
                      in: '$$group.code',
                    },
                  },
                  // groupAncestorCodes: {
                  //   $function: {
                  //     body: function (groups) {
                  //       return groups.reduce(
                  //         (accumulator, group) => ({
                  //           ...accumulator,
                  //           [group.type]: [
                  //             ...(accumulator[group.type] || []),
                  //             group.code,
                  //           ],
                  //         }),
                  //         {},
                  //       );
                  //     },
                  //     args: ['$groupAncestorCodes'],
                  //     lang: 'js',
                  //   },
                  // },
                },
              },
            ],
            as: 'person',
          },
        },
        { $set: { person: { $first: '$person' } } },
        {
          $project: {
            _id: 0,
            month: '$_id.month',
            username: '$_id.user',
            groupAncestorCodes: '$person.groupAncestorCodes',
            homeStation: '$person.homeStation',
            role: '$person.role',
            count: 1,
          },
        },
      ];

      return api.post('pipeline/audits', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
