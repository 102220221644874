import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useTags() {
  const queryKey = ['tags'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      log('Read', 'Tags');

      return api
        .post('pipeline/tags', {
          json: [
            {
              $project: {
                identifier: true,
                title: true,
                type: true,
                description: true,
                items: true,
                // created: true,
              },
            },
            { $sort: { type: 1, title: 1 } },
          ],
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
