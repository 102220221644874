import { Layer, Source } from 'react-map-gl/maplibre';

export function LocationsSource({ id, data, visibility }) {
  return (
    <Source id={id} type="geojson" data={data} tolerance={0}>
      <Layer
        id={`${id}-fill-layer`}
        type="fill"
        layout={{
          visibility,
        }}
        paint={{
          'fill-color': '#000',
          'fill-opacity': 0.25,
        }}
      />
      <Layer
        id={`${id}-outline-layer`}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
          visibility,
        }}
        paint={{
          'line-color': '#fff',
          'line-width': 2,
        }}
      />
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-house',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.125, 12, 1],
          visibility,
        }}
        paint={{
          'icon-color': '#000',
          'icon-halo-color': '#fff',
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'icon-image': [
            'match',
            ['get', 'type'],
            'Base',
            'ir3-base',
            'Court',
            'ir3-court',
            'Hospital',
            'ir3-hospital',
            'Police Station',
            'ir3-police-station',
            'School',
            'ir3-school',
            'Workshop',
            'ir3-workshop',
            'ir3-base',
          ],
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.075, 12, 0.6],
          visibility,
        }}
        paint={{
          'icon-color': '#fff',
        }}
      />
    </Source>
  );
}
