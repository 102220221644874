import { getStatusColor } from '@/utils';
import { Layer, Source } from 'react-map-gl/maplibre';

export function ObjectivesSource({ id, data, visibility }) {
  return (
    <Source id={id} type="geojson" data={data} tolerance={0}>
      <Layer
        id={`${id}-fill-layer`}
        type="fill"
        layout={{
          visibility,
        }}
        paint={{
          'fill-color': [
            'match',
            ['get', 'status'],
            'active',
            getStatusColor('active'),
            'inactive',
            getStatusColor('inactive'),
            getStatusColor('default'),
          ],
          'fill-opacity': 0.25,
        }}
      />
      <Layer
        id={`${id}-outline-layer`}
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
          visibility,
        }}
        paint={{
          'line-color': '#fff',
          'line-width': 2,
        }}
      />
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-hexagon',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.125, 12, 1],
          visibility,
        }}
        paint={{
          'icon-color': [
            'match',
            ['get', 'status'],
            'active',
            getStatusColor('active'),
            'inactive',
            getStatusColor('inactive'),
            getStatusColor('default'),
          ],
          'icon-halo-color': '#fff',
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-objective',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.075, 12, 0.6],
          visibility,
        }}
        paint={{
          'icon-color': '#fff',
        }}
      />
    </Source>
  );
}
