import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useUncheckedVehicles(groupCode) {
  const queryKey = ['uncheckedVehicles', groupCode];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const now = new Date();
      const today = startOfDay(now);
      const twoWeeksAgo = subDays(today, 14);
      const json = [
        {
          $match: {
            lastPollTime: { $gt: twoWeeksAgo },
            $or: [
              { disposalDate: { $exists: false } },
              { disposalDate: { $gte: now } },
            ],
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groups',
          },
        },
        groupCode && { $match: { 'groups.code': groupCode } },
        {
          $set: {
            groups: {
              $map: {
                input: '$groups',
                as: 'group',
                in: {
                  code: '$$group.code',
                  type: '$$group.type',
                },
              },
            },
          },
        },
        {
          $lookup: {
            from: 'vehicleStates',
            let: { identificationNumber: '$identificationNumber' },
            pipeline: [
              {
                $match: {
                  $and: [
                    { type: 'Check' },
                    {
                      $expr: {
                        $eq: [
                          '$identificationNumber',
                          '$$identificationNumber',
                        ],
                      },
                    },
                  ],
                },
              },
              { $sort: { time: -1 } },
              { $limit: 1 },
              {
                $project: {
                  _id: false,
                  time: true,
                  distanceKilometres: true,
                },
              },
            ],
            as: 'lastCheck',
          },
        },
        { $unwind: { path: '$lastCheck', preserveNullAndEmptyArrays: true } },
        {
          $set: {
            daysWithoutCheck: {
              $dateDiff: {
                startDate: '$lastCheck.time',
                endDate: '$lastPollTime',
                unit: 'day',
              },
            },
          },
        },
        { $match: { daysWithoutCheck: { $gt: 7 } } },
        { $sort: { daysWithoutCheck: -1 } },
        {
          $project: {
            _id: false,
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            homeStation: true,
            groups: true,
            lastPollTime: true,
            lastCheck: true,
          },
        },
      ].filter(Boolean);

      const response = await api
        .post('pipeline/vehicles', { json, signal })
        .json();
      return response.map((vehicle) => ({
        ...vehicle,
        groups: vehicle.groups.reduce(
          (accumulator, group) => ({
            ...accumulator,
            [group.type]: [...(accumulator[group.type] ?? []), group.code],
          }),
          {},
        ),
      }));
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
