import { getTextWidth } from '@/utils';
import {
  Autocomplete,
  Box,
  List,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { forwardRef, useRef } from 'react';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const getComputedWidthPopper = (options, font, padding) =>
  function ComputedWidthPopper({ style: { width, ...style }, key, ...props }) {
    const computedWidth =
      Math.ceil(
        Math.max(
          ...(options || []).map((option) =>
            getTextWidth(
              option.label ??
                options?.find((o) => o.value === option)?.label ??
                '',
              font,
            ),
          ),
        ),
      ) + padding;

    return (
      <StyledPopper
        key={key}
        {...props}
        style={{ width: Math.max(computedWidth, width), ...style }}
        placement="bottom-start"
      />
    );
  };

const VirtualisedListbox = forwardRef(function VirtualisedListbox(
  { children: options, role, ...props },
  ref,
) {
  const scrollRef = useRef(null);
  const virtualizer = useVirtualizer({
    count: options.length,
    estimateSize: () => 36, //* NOTE: You may have to play around with this value.
    getScrollElement: () => scrollRef.current,
  });

  return (
    <Box ref={ref}>
      <List
        {...props}
        role={role}
        ref={scrollRef}
        component="div"
        sx={{
          position: 'relative',
          height: virtualizer.getTotalSize(),
        }}
      >
        {virtualizer.getVirtualItems().map((item) => {
          const [{ key, ...props }, option] = options[item.index];

          return (
            <Typography
              component="li"
              key={key}
              {...props}
              noWrap
              sx={{
                py: 0.5,
                width: '100%',
                position: 'absolute',
                transform: `translateY(${item.start}px)`,
              }}
            >
              {option.label}
            </Typography>
          );
        })}
      </List>
    </Box>
  );
});

export function FilterAutocomplete({ value, onChange, options, ...props }) {
  function handleSelectionChange(event, selection) {
    onChange(selection.map((option) => option.value ?? option));
  }

  return (
    <Autocomplete
      value={value ?? []}
      onChange={handleSelectionChange}
      multiple
      size="small"
      options={options}
      getOptionLabel={(option) =>
        option.label ?? options?.find((o) => o.value === option)?.label ?? ''
      }
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => <TextField {...params} {...props} />}
      disableListWrap
      renderOption={(props, option) => [props, option]}
      slots={{
        popper: getComputedWidthPopper(options, '16px Roboto', 44),
      }}
      slotProps={{
        listbox: {
          component: VirtualisedListbox,
        },
      }}
    />
  );
}
