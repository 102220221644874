import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useTotalAttendedIncidents(
  groupType,
  groupCode,
  subgroupType,
  days,
) {
  const queryKey = [
    'totalAttendedIncidents',
    groupType,
    groupCode,
    subgroupType,
    days,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            attended: true,
            startTime: { $gt: start },
            'person.groupCodes': groupCode,
          },
        },
        ...(subgroupType !== 'COLLAR_NUMBER'
          ? [
              {
                $join: 'groups',
                from: 'groupCodes',
                localField: 'person.groupCodes',
                foreignField: 'code',
                pipeline: [
                  { $match: { type: subgroupType } },
                  { $project: { _id: false, code: true, name: true } },
                ],
                as: 'group',
              },
              { $unwind: '$group' },
            ]
          : []),
        {
          $group: {
            _id: {
              identifier: {
                $ifNull: [
                  subgroupType === 'COLLAR_NUMBER'
                    ? '$person.collarNumber'
                    : `$group.code`,
                  'UNKNOWN',
                ],
              },
              grade: '$incident.grade',
            },
            incidentNumbers: { $addToSet: '$incident.number' },
          },
        },
        {
          $project: {
            _id: false,
            identifier: '$_id.identifier',
            grade: '$_id.grade',
            count: { $size: '$incidentNumbers' },
          },
        },
      ];

      const response = await api
        .post('pipeline/personIncidentResponses', { json, signal })
        .json();

      return response.reduce(
        (accumlator, { identifier, grade, count }) => {
          if (!accumlator.counts[identifier]) {
            accumlator.counts[identifier] = {};
          }

          accumlator.counts[identifier][grade ?? 'UNKNOWN'] = count;
          accumlator.grades.add(grade ?? 'UNKNOWN');

          return accumlator;
        },
        { counts: {}, grades: new Set() },
      );
    },
    placeholderData: { counts: {}, grades: new Set() },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
