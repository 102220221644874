import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useObjectives() {
  const queryKey = ['objectives'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      log('Read', 'Objectives');

      return api
        .get('objectives', {
          searchParams: encodeParams({
            projection: {
              identifier: true,
              title: true,
              startTime: true,
              endTime: true,
              wards: true,
              created: true,
              boundaryType: true,
              occurrenceNumber: true,
            },
          }),
          signal,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
