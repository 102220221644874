import { api } from '@/apis';
import { log } from '@/utils';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { DriverScore, DriverScoresParams } from './types';

export function useDriverScores(
  params: DriverScoresParams,
): UseQueryResult<DriverScore[]> & { cancel: () => void } {
  const queryKey = ['driverScores', params];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      log('Read', 'Driver Scores', params);

      const scores: DriverScore[] = await api
        .post('driverScores', { json: params, signal })
        .json();

      return scores.sort((a: DriverScore, b: DriverScore) =>
        (a.person?.collarNumber ?? '').localeCompare(
          b.person?.collarNumber ?? '',
        ),
      );
    },
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
