import { Parameters } from '@/components/controls';
import { useAggregate, useDocumentTitle, useOptions } from '@/hooks';
import { isEmpty } from '@/utils';
import { events } from '@/utils/config';
import { Box, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { StopDetail } from './StopDetail';
import {
  columnsFn,
  downloadPipelineFn,
  pipelineFn,
  stateAtom,
  totalsPipelineFn,
} from './utils';

const {
  eventFilters: { stops: eventFilters },
} = events;

export function Stops() {
  useDocumentTitle('IR3 | Stops');
  const [{ sorting, pagination, query, parameters }, setState] =
    useAtom(stateAtom);
  const collection = 'stops';
  const { data: vehicleCategories } = useOptions('vehicleGroup');
  const pipeline = useMemo(
    () =>
      pipelineFn(
        pagination,
        query,
        sorting,
        vehicleCategories.map(({ value }) => value),
      ),
    [pagination, query, sorting, vehicleCategories],
  );
  const totalsPipeline = useMemo(() => totalsPipelineFn(query), [query]);
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    !isEmpty(query),
    1000 * 60 * 60,
  );
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), 1000 * 60 * 60);
  const columns = useMemo(
    () => columnsFn(aggregated[0] ?? {}, vehicleCategories),
    [aggregated, vehicleCategories],
  );
  const csvColumns = columns
    .map(({ header, id, accessorKey }) => ({
      header,
      key: id ?? accessorKey,
    }))
    .concat({ header: 'Link', key: 'link' });

  function renderDetailPanel(cell) {
    return cell.row.original.point ? (
      <StopDetail stop={cell.row.original} />
    ) : null;
  }

  function handleCancel() {
    cancel();
    cancelTotals();
    setState((state) => ({ ...state, query: {} }));
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (event, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  const table = useMaterialReactTable({
    columns,
    data,
    state: {
      density: 'compact',
      sorting,
      pagination,
      isLoading: isFetching,
    },
    defaultColumn: { size: 0, enableEditing: false },
    rowCount: aggregated[0]?.total ?? 0,
    onPaginationChange: handleTableStateChange('pagination'),
    onSortingChange: handleTableStateChange('sorting'),
    renderDetailPanel,
    enableExpandAll: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTablePaperProps: { elevation: 0, variant: 'elevation' },
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 280px)',
        maxHeight: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
  });

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          collection={collection}
          onFetch={handleStateChangeWithEvent('query')}
          onCancel={handleCancel}
          isFetching={isFetching || totalsFetching}
          value={parameters}
          onChange={handleStateChange('parameters')}
          sx={{ pt: 1, height: 1 }}
          vehicle
          lastDriver
          eventFilters={eventFilters}
          pipelineFn={downloadPipelineFn(
            collection,
            vehicleCategories.map(({ value }) => value),
          )}
          columns={csvColumns}
        />
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
