import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNotices(groupCodes) {
  const queryKey = ['notices', groupCodes];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const searchParams = new URLSearchParams(
        groupCodes.map((code) => ['groupCodes', code]),
      );

      return briefingApi.get('notices', { searchParams, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
