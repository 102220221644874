import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useBrief(code) {
  const queryKey = ['briefs', code];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      if (code === 'new') {
        return {
          title: '',
          groupCodes: [],
          excludedSections: [],
        };
      }

      return briefingApi.get(`briefs/${code}`, { signal }).json();
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
