import { api } from '@/apis';
import { log } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useRfidCards() {
  const queryKey = ['rfidCards'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $project: {
            reference: true,
            category: true,
            type: true,
            label: true,
            time: true,
          },
        },
        {
          $lookup: {
            from: 'people',
            localField: '_id',
            foreignField: 'rfidCards.reference',
            as: 'people',
          },
        },
        {
          $set: {
            assignmentCount: {
              $size: {
                $filter: {
                  input: '$people',
                  as: 'person',
                  cond: { $ne: ['$$person.deleted', true] },
                },
              },
            },
          },
        },
        { $unset: 'people' },
      ];

      log('Read', useDallasKeys ? 'Dallas Keys' : 'RFID Cards');

      return api.post('pipeline/rfidCards', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
