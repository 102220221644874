import { api } from '@/apis';
import { addPropertiesPrefixToMatch, isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useVehicleBoundaryVisits({ boundary, query: filter }) {
  const queryKey = ['vehicleBoundaryVisits', boundary, filter];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { startTime, endTime, ...match } = filter;

      const featureMatch = addPropertiesPrefixToMatch(match);
      const featurePipeline = [
        {
          $match: {
            time: { $gte: endTime.$gte, $lte: startTime.$lt },
            position: { $geoWithin: { $geometry: boundary } },
          },
        },
        {
          $project: {
            _id: false,
            imei: true,
            time: true,
            speedKilometresPerHour: '$speedKilometresPerHour',
            distanceKilometres: true,
            coordinates: '$position.coordinates',
            orderNumber: true,
          },
        },
        {
          $group: {
            _id: '$imei',
            polls: {
              $push: {
                time: '$time',
                speedKilometresPerHour: '$speedKilometresPerHour',
                distanceKilometres: '$distanceKilometres',
                coordinates: '$coordinates',
                orderNumber: '$orderNumber',
              },
            },
          },
        },
        {
          $project: {
            reduction: {
              $reduce: {
                input: {
                  $sortArray: { input: '$polls', sortBy: { orderNumber: 1 } },
                },
                initialValue: { visits: [], lastOrderNumber: null },
                in: {
                  $cond: {
                    if: {
                      $ne: [
                        { $add: ['$$value.lastOrderNumber', 1] },
                        '$$this.orderNumber',
                      ],
                    },
                    then: {
                      visits: {
                        $concatArrays: [
                          '$$value.visits',
                          [
                            {
                              id: {
                                $concat: [
                                  '$_id',
                                  { $toString: { $size: '$$value.visits' } },
                                ],
                              },
                              coordinates: ['$$this.coordinates'],
                              properties: {
                                imei: '$_id',
                                startTime: '$$this.time',
                                endTime: '$$this.time',
                                maxSpeedKilometresPerHour:
                                  '$$this.speedKilometresPerHour',
                                startDistanceKilometres:
                                  '$$this.distanceKilometres',
                                endDistanceKilometres:
                                  '$$this.distanceKilometres',
                              },
                            },
                          ],
                        ],
                      },
                      lastOrderNumber: '$$this.orderNumber',
                    },
                    else: {
                      visits: {
                        $concatArrays: [
                          {
                            $slice: [
                              '$$value.visits',
                              {
                                $subtract: [{ $size: '$$value.visits' }, 1],
                              },
                            ],
                          },
                          [
                            {
                              id: { $last: '$$value.visits.id' },
                              coordinates: {
                                $concatArrays: [
                                  { $last: '$$value.visits.coordinates' },
                                  ['$$this.coordinates'],
                                ],
                              },
                              properties: {
                                imei: '$_id',
                                startTime: {
                                  $last: '$$value.visits.properties.startTime',
                                },
                                endTime: '$$this.time',
                                maxSpeedKilometresPerHour: {
                                  $max: [
                                    '$$this.speedKilometresPerHour',
                                    {
                                      $last:
                                        '$$value.visits.properties.maxSpeedKilometresPerHour',
                                    },
                                  ],
                                },
                                startDistanceKilometres: {
                                  $last:
                                    '$$value.visits.properties.startDistanceKilometres',
                                },
                                endDistanceKilometres:
                                  '$$this.distanceKilometres',
                              },
                            },
                          ],
                        ],
                      },
                      lastOrderNumber: '$$this.orderNumber',
                    },
                  },
                },
              },
            },
          },
        },
        { $unset: ['reduction.lastOrderNumber'] },
        { $unwind: '$reduction.visits' },
        { $replaceRoot: { newRoot: '$reduction.visits' } },
        {
          $project: {
            id: true,
            type: 'Feature',
            properties: {
              imei: true,
              startTime: true,
              endTime: true,
              durationSeconds: {
                $dateDiff: {
                  startDate: '$properties.startTime',
                  endDate: '$properties.endTime',
                  unit: 'second',
                },
              },
              maxSpeedMilesPerHour: {
                $multiply: [
                  '$properties.maxSpeedKilometresPerHour',
                  0.62137119,
                ],
              },
              distanceMiles: {
                $multiply: [
                  {
                    $subtract: [
                      '$properties.endDistanceKilometres',
                      '$properties.startDistanceKilometres',
                    ],
                  },
                  0.62137119,
                ],
              },
            },
            geometry: {
              type: {
                $cond: [
                  { $gt: [{ $size: '$coordinates' }, 1] },
                  'LineString',
                  'Point',
                ],
              },
              coordinates: {
                $cond: [
                  { $gt: [{ $size: '$coordinates' }, 1] },
                  '$coordinates',
                  { $arrayElemAt: ['$coordinates', 0] },
                ],
              },
            },
          },
        },
        {
          $lookup: {
            from: 'vehicleHistory',
            localField: 'properties.imei',
            foreignField: 'vehicle.telematicsBoxImei',
            as: 'properties.vehicle',
            let: { startTime: '$properties.startTime' },
            pipeline: [
              { $match: { $expr: { $lt: ['$time', '$$startTime'] } } },
              { $sort: { time: -1 } },
              { $limit: 1 },
              { $replaceRoot: { newRoot: '$vehicle' } },
            ],
          },
        },
        {
          $set: {
            'properties.vehicle': {
              $ifNull: [
                { $first: '$properties.vehicle' },
                { telematicsBoxImei: '$properties.imei' },
              ],
            },
          },
        },
        !isEmpty(featureMatch) && { $match: featureMatch },
        { $sort: { 'properties.startTime': 1 } },
      ].filter(Boolean);
      const featuresRequest = api
        .post('pipeline/telematicsBoxPolls', {
          json: featurePipeline,
          signal,
        })
        .json();

      const tripPipeline = [
        {
          $match: {
            ...filter,
            path: {
              $geoIntersects: {
                $geometry: boundary,
              },
            },
          },
        },
        {
          $project: {
            _id: false,
            identifier: true,
            equipmentActivations: { emergencyOn: true },
            driver: {
              code: true,
              forenames: true,
              surname: true,
              collarNumber: true,
              role: true,
            },
            vehicle: {
              telematicsBoxImei: true,
              registrationNumber: true,
              fleetNumber: true,
              role: true,
              type: true,
            },
            rfidCard: {
              reference: true,
              type: true,
              label: true,
            },
            startTime: true,
            endTime: true,
            durationSeconds: true,
            distanceMiles: {
              $multiply: ['$distanceKilometres', 0.62137119],
            },
            maxSpeedMilesPerHour: {
              $multiply: ['$maxSpeedKilometresPerHour', 0.62137119],
            },
          },
        },
      ];
      const tripsRequest = api
        .post('pipeline/trips', {
          json: tripPipeline,
          signal,
        })
        .json();

      const [features, trips] = await Promise.all([
        featuresRequest,
        tripsRequest,
      ]);

      log('Read', 'Vehicle Boundary Visits', { boundary, query: filter });

      return features.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          trips: trips.filter(
            (trip) =>
              trip.vehicle?.telematicsBoxImei === feature.properties.imei &&
              trip.startTime < feature.properties.endTime &&
              trip.endTime > feature.properties.startTime,
          ),
        },
      }));
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!boundary && !isEmpty(filter),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
