import { api } from '@/apis';
import { useOptions } from '@/hooks';
import { log } from '@/utils';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  TripsAccelerationSummariesParams,
  TripsAccelerationSummary,
} from './types';

export function useTripAccelerationSummaries({
  startTime,
  endTime,
  query: filter,
  pagination,
  sorting = [],
  mode,
  excludeExempt,
}: TripsAccelerationSummariesParams): UseQueryResult<
  TripsAccelerationSummary[]
> & { cancel: () => void } {
  const { data: classifications } = useOptions('tripClassification', {
    excludeFromDriverScores: true,
  });
  const exemptTripClassifications = useMemo(
    () => (classifications ?? []).map(({ value }) => value),
    [classifications],
  );

  const queryKey = [
    'tripAccelerationSummaries',
    startTime,
    endTime,
    filter,
    pagination,
    sorting,
    mode,
    excludeExempt,
    exemptTripClassifications,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const peopleResponse: { code: string }[] = await api
        .post('pipeline/people', {
          json: [{ $match: filter }, { $project: { _id: false, code: true } }],
        })
        .json();

      const summaries: TripsAccelerationSummary[] = await api
        .post('pipeline/vehicleTripAccelerationSummaries', {
          json: [
            {
              $match: {
                startTime: { $gte: startTime, $lt: endTime },
                durationSeconds: { $gte: 60 },
                'driver.code': {
                  $in: peopleResponse.map((person) => person.code),
                },
                ...(excludeExempt
                  ? { classification: { $nin: exemptTripClassifications } }
                  : {}),
                ...(mode === 'emergency'
                  ? { 'equipmentActivations.emergencyOn': true }
                  : mode === 'nonEmergency'
                    ? { 'equipmentActivations.emergencyOn': { $ne: true } }
                    : {}),
              },
            },
            {
              $project: {
                _id: false,
                identifier: true,
                startTime: true,
                endTime: true,
                driver: {
                  code: '$driver.code',
                  collarNumber: '$driver.collarNumber',
                  forenames: '$driver.forenames',
                  surname: '$driver.surname',
                },
                vehicle: {
                  registrationNumber: '$vehicle.registrationNumber',
                  fleetNumber: '$vehicle.fleetNumber',
                  identificationNumber: '$vehicle.identificationNumber',
                  telematicsBoxImei: '$vehicle.telematicsBoxImei',
                },
                classification: true,
                distanceKilometres: true,
                durationSeconds: true,
                equipmentActivations: {
                  emergencyOn: '$equipmentActivations.emergencyOn',
                },
                excessAccelerationSeconds: {
                  $add: [
                    '$excessAccelerationDurationSeconds',
                    '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                  ],
                },
                excessBrakingSeconds: {
                  $add: [
                    '$excessBrakingDurationSeconds',
                    '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                  ],
                },
                excessCorneringSeconds: {
                  $add: [
                    '$excessCorneringDurationSeconds',
                    '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                  ],
                },
                speedInfractionsSeconds: {
                  $add: [
                    '$speedInfractionsDurationSeconds',
                    '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                  ],
                },
                score: {
                  $multiply: [
                    {
                      $divide: [
                        {
                          $subtract: [
                            '$durationSeconds',
                            {
                              $add: [
                                '$excessAccelerationDurationSeconds',
                                '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                                '$excessBrakingDurationSeconds',
                                '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                                '$excessCorneringDurationSeconds',
                                '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                                '$speedInfractionsDurationSeconds',
                                '$speedInfractionsWithEmergencyEquipmentDurationSeconds',
                              ],
                            },
                          ],
                        },
                        '$durationSeconds',
                      ],
                    },
                    100,
                  ],
                },
              },
            },
            sorting.length > 0 && {
              $sort: sorting.reduce(
                (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
                {},
              ),
            },
            { $skip: pagination.pageIndex * pagination.pageSize },
            { $limit: pagination.pageSize },
          ].filter(Boolean),
          signal,
        })
        .json();

      log('Read', 'Trip Acceleration Summaries', {
        startTime,
        endTime,
        query: filter,
        pagination,
        sorting,
        mode,
        excludeExempt,
      });

      return summaries;
    },
    placeholderData: (previousData) => previousData ?? [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
