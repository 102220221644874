import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useTelematicsBoxes() {
  const queryKey = ['telematicsBoxes'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $project: {
            imei: true,
            type: true,
            label: true,
            firstContactTime: true,
            lastPoll: '$mostRecentPoll',
          },
        },
        {
          $lookup: {
            from: 'vehicles',
            localField: '_id',
            foreignField: 'telematicsBoxImei',
            as: 'vehicles',
          },
        },
        {
          $set: {
            assignmentCount: {
              $size: {
                $filter: {
                  input: '$vehicles',
                  as: 'vehicle',
                  cond: { $ne: ['$$vehicle.deleted', true] },
                },
              },
            },
          },
        },
        { $unset: 'vehicles' },
      ];

      log('Read', 'Telematics Boxes');

      return api.post('pipeline/telematicsBoxes', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
