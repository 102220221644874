import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useNichePeople({
  classificatonCodes = [],
  risk,
  flagCodes = [],
  groupType,
  groupCodes = [],
}) {
  const queryKey = [
    'nichePeople',
    classificatonCodes,
    risk,
    flagCodes,
    groupType,
    groupCodes,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const searchParams = new URLSearchParams(
        [
          ...classificatonCodes.map((code) => ['classificationCodes', code]),
          risk && ['risk', risk],
          ...flagCodes.map((code) => ['flagCodes', code]),
          groupType && ['groupType', groupType],
          ...groupCodes.map((code) => ['groupCodes', code]),
        ].filter(Boolean),
      );

      return briefingApi.get('niche/people', { searchParams, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
