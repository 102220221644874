import {
  DateTimePicker,
  FetchButton,
  FilterAutocomplete,
  NumericFilterField,
} from '@/components/controls';
import { useAggregate, useDocumentTitle, useOptions } from '@/hooks';
import { downloadCSV } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { DrillDown } from './DrillDown';
import { useLastContactOptions } from './useLastContactOptions';
import { columnsFn, pipelineFn, stateAtom } from './utils';

export function LastContact() {
  useDocumentTitle('IR3 | Last Contact');
  const [
    {
      lastContactTime,
      imeis,
      fleetNumbers,
      registrationNumbers,
      disposed,
      ignition,
      batteryVoltage,
      signalStrength,
      gpsValidPollPercentage,
      columnVisibility,
      ...groups
    },
    setState,
  ] = useAtom(stateAtom);
  const { data: vehicleCategories } = useOptions('vehicleGroup');
  const { data: options } = useLastContactOptions(
    vehicleCategories.map(({ value }) => value),
  );
  const {
    data: telematicsBoxes,
    isLoading,
    isFetching,
    refetch,
  } = useAggregate(
    'telematicsBoxes',
    pipelineFn(
      lastContactTime,
      imeis,
      fleetNumbers,
      registrationNumbers,
      vehicleCategories.map(({ value }) => value),
      groups,
      disposed,
      ignition,
      batteryVoltage,
      signalStrength,
      gpsValidPollPercentage,
    ),
    false,
    1000 * 60 * 60,
  );

  const handleChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  const handlSelectChange = (name) => (event) => {
    setState((state) => ({ ...state, [name]: event.target.value }));
  };

  function handleRefreshClick() {
    refetch();
  }

  function renderDetailPanel(cell) {
    return <DrillDown entry={cell.row.original} />;
  }

  function handleDownloadClick() {
    const data = telematicsBoxes.map((box) => ({
      ...box,
      mostRecentTime: box.mostRecentTime
        ? format(box.mostRecentTime, 'dd/MM/yyyy HH:mm:ss')
        : '',
      disposalDate: box.disposalDate
        ? format(box.disposalDate, 'dd/MM/yyyy')
        : '',
      ignitionOn: box.ignitionOn ? 'On' : 'Off',
    }));
    const csvColumns = [
      {
        label: 'IMEI',
        key: 'imei',
      },
      {
        label: 'Last Contact Time',
        key: 'mostRecentTime',
      },
      {
        label: 'Disposal Date',
        key: 'disposalDate',
      },
      {
        label: 'Fleet Number',
        key: 'fleetNumber',
      },
      {
        label: 'Registration',
        key: 'registrationNumber',
      },
      {
        label: 'Ignition',
        key: 'ignitionOn',
      },
      {
        label: 'Battery Voltage',
        key: 'batteryVoltage',
      },
      {
        label: 'Signal Strength',
        key: 'signalStrength',
      },
      {
        label: 'Valid GPS Rate (%)',
        key: 'gpsValidPollPercentage',
      },
      {
        label: 'Location Type',
        key: 'location.type',
      },
      {
        label: 'Location Name',
        key: 'location.name',
      },
      ...vehicleCategories.map(({ value, label }) => ({
        label,
        key: `vehicle.groups.${value}`,
      })),
    ];

    downloadCSV(data, 'last-contact.csv', csvColumns);
  }

  const table = useMaterialReactTable({
    data: telematicsBoxes,
    columns: columnsFn(vehicleCategories),
    state: {
      density: 'compact',
      isLoading: isLoading || isFetching,
      columnVisibility,
    },
    defaultColumn: { size: 0 },
    muiTablePaperProps: { elevation: 0, variant: 'elevation' },
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 280px)',
        height: 'calc(100vh - 160px)',
      },
    },
    enableStickyHeader: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    renderDetailPanel,
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Tooltip title="Download data">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
    onColumnVisibilityChange: handleTableStateChange('columnVisibility'),
  });

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          pt: 1,
        }}
      >
        <Stack sx={{ px: 1, height: 1 }}>
          <Stack
            spacing={1}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              flex: 1,
              py: 1,
            }}
          >
            <DateTimePicker
              label="Last Contact Before"
              value={lastContactTime}
              onChange={handleChange('lastContactTime')}
              clearable
              disableFuture
            />
            <Divider>
              <Typography variant="caption" color="textSecondary">
                Telematics Box
              </Typography>
            </Divider>
            <FilterAutocomplete
              label="IMEI"
              value={imeis}
              onChange={handleChange('imeis')}
              options={options?.imei}
            />
            <Divider>
              <Typography variant="caption" color="textSecondary">
                Vehicle
              </Typography>
            </Divider>
            <FilterAutocomplete
              label="Fleet Number"
              value={fleetNumbers}
              onChange={handleChange('fleetNumbers')}
              options={options?.fleetNumber}
            />
            <FilterAutocomplete
              label="Registration"
              value={registrationNumbers}
              onChange={handleChange('registrationNumbers')}
              options={options?.registrationNumber}
            />
            {vehicleCategories.map(({ value, label }) => (
              <FilterAutocomplete
                key={value}
                label={label}
                value={groups?.[value] ?? []}
                onChange={handleChange(value)}
                options={options?.[value] ?? []}
              />
            ))}
            <TextField
              size="small"
              fullWidth
              select
              label="Disposed"
              value={disposed}
              onChange={handlSelectChange('disposed')}
            >
              <MenuItem value={2}>All</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </TextField>
            <Divider>
              <Typography variant="caption" color="textSecondary">
                State
              </Typography>
            </Divider>
            <TextField
              size="small"
              fullWidth
              select
              label="Ignition"
              value={ignition}
              onChange={handlSelectChange('ignition')}
            >
              <MenuItem value={2}>Either</MenuItem>
              <MenuItem value={true}>On</MenuItem>
              <MenuItem value={false}>Off</MenuItem>
            </TextField>

            <NumericFilterField
              size="small"
              label="Battery Voltage"
              value={batteryVoltage}
              onChange={handleChange('batteryVoltage')}
              unit="v"
            />
            <NumericFilterField
              size="small"
              label="Signal Strength"
              value={signalStrength}
              onChange={handleChange('signalStrength')}
              unit="dBm"
            />
            <NumericFilterField
              size="small"
              label="Valid GPS Rate"
              value={gpsValidPollPercentage}
              onChange={handleChange('gpsValidPollPercentage')}
              unit="%"
            />
          </Stack>
          <Divider />
          <Stack spacing={0.5} direction="row" sx={{ py: 1 }}>
            <FetchButton
              fullWidth
              isFetching={isLoading || isFetching}
              onClick={handleRefreshClick}
            />
          </Stack>
        </Stack>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
