import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

/**
 * A hook that fetches a boundary.
 * @param {string} type - The type of boundary to fetch.
 * @param {string} id - The ID of the boundary to fetch.
 * @returns {import('@tanstack/react-query').UseQueryResult<import('@types/geojson').Polygon> & { cancel: () => void }} - The query result and a function to cancel the query.
 */
export function useBoundary(type, id) {
  const queryKey = [type, id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      switch (type) {
        case 'Location': {
          const location = await api
            .get(`locations/${id}`, {
              searchParams: encodeParams({ projection: { boundary: true } }),
              signal,
            })
            .json();

          return location?.boundary;
        }
        default:
          return;
      }
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!type && !!id,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
