import { api } from '@/apis';
import { NormalDistribution, epochHoursToHistogram, log, range } from '@/utils';
import { baseType } from '@/utils/config';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUnixTime } from 'date-fns';
import { mean as meanValue, std as standardDeviation } from 'mathjs';

export function useVehicleAvailability(
  startTime,
  endTime,
  filter,
  homeOnly,
  customConfidence,
) {
  const queryKey = [
    'vehicleAvailability',
    startTime,
    endTime,
    filter,
    homeOnly,
    customConfidence,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const grouping = 'role';
      const json = [
        {
          $match: {
            startTime: { $lt: endTime },
            endTime: { $gte: startTime },
            'locations.type': baseType.label,
            ...(homeOnly
              ? {
                  $expr: {
                    $or: [
                      {
                        $in: [
                          '$vehicle.homeStation',
                          { $ifNull: ['$aliases.TRANMAN', []] },
                        ],
                      },
                      {
                        $in: [
                          '$vehicle.homeStation',
                          { $ifNull: ['$locations.code', []] },
                        ],
                      },
                      {
                        $in: [
                          '$vehicle.homeStation',
                          { $ifNull: ['$locations.name', []] },
                        ],
                      },
                    ],
                  },
                }
              : {}),
          },
        },
        {
          $project: {
            baseLocation: {
              $arrayElemAt: [
                {
                  $filter: {
                    input: '$locations',
                    as: 'location',
                    cond: { $eq: ['$$location.type', baseType.label] },
                  },
                },
                0,
              ],
            },
            startTime: true,
            endTime: true,
            vehicle: true,
          },
        },
        {
          $group: {
            _id: {
              locationCode: '$baseLocation.code',
              grouping: { $ifNull: [`$vehicle.${grouping}`, ''] },
            },
            hours: {
              $push: {
                $map: {
                  input: {
                    $range: [
                      {
                        $floor: {
                          $divide: [
                            {
                              $subtract: [
                                '$startTime',
                                {
                                  $toDate: new Date('1970-01-01T00:00:00.000Z'),
                                },
                              ],
                            },
                            1000,
                          ],
                        },
                      },
                      {
                        $floor: {
                          $divide: [
                            {
                              $subtract: [
                                '$endTime',
                                {
                                  $toDate: new Date('1970-01-01T00:00:00.000Z'),
                                },
                              ],
                            },
                            1000,
                          ],
                        },
                      },
                      3600,
                    ],
                  },
                  as: 'time',
                  in: { $floor: { $divide: [{ $toDecimal: '$$time' }, 3600] } },
                },
              },
            },
          },
        },
      ];

      const [locationsResult, vehiclesResult, telematicsResult, stopsResult] =
        await Promise.all([
          api
            .post('pipeline/locations', {
              json: [{ $project: { code: true, name: true, type: true } }],
            })
            .json(),
          api
            .post('pipeline/vehicles', {
              json: [
                {
                  $project: { telematicsBoxImei: true, role: true, type: true },
                },
              ],
            })
            .json(),
          api
            .post('pipeline/telematicsBoxes', {
              json: [
                { $match: { events: { $exists: true } } },
                {
                  $project: {
                    imei: true,
                    events: true,
                    'mostRecentPoll.time': true,
                  },
                },
              ],
            })
            .json(),
          api.post('pipeline/stops', { json, signal }).json(),
        ]);

      const locationsByCode = Object.fromEntries(
        locationsResult.map((location) => [location.code, location]),
      );
      const vehiclesByImei = Object.fromEntries(
        vehiclesResult.map((vehicle) => [vehicle.telematicsBoxImei, vehicle]),
      );

      // an epochHour is the number of hours since 1/1/1970
      function dateToEpochHour(date) {
        if (date) {
          return Math.floor(getUnixTime(date) / 3600);
        }

        return null;
      }
      const startEpochHour = dateToEpochHour(startTime);
      const endEpochHour = dateToEpochHour(endTime);

      // STOPs are only created when a vehicle starts again, so there could be vehicles
      // that are currently at the location with no STOP. The most recent stop event of
      // the telematics box has the current location and from the start time of that event
      // we can work out how long it has been at the location (so far)
      const currentStops = telematicsResult
        .filter(
          (t) =>
            t.events &&
            t.events.some(
              (e) =>
                e.eventType === 'STOP' &&
                e.startTime < endTime &&
                e.locations?.length > 0,
            ),
        )
        .map((t) => {
          const stopEvent = t.events.find((e) => e.eventType === 'STOP');
          const startEpochHour = dateToEpochHour(
            // start from the later of when it arrived at location or the start of the query
            stopEvent.startTime > startTime ? stopEvent.startTime : startTime,
          );
          return {
            locationCode: stopEvent.locations[0].code,
            grouping: vehiclesByImei[t.imei]?.[grouping] || '',
            hours: range(startEpochHour, endEpochHour, 1),
          };
        });

      const stops = stopsResult;

      function getStopKey(stop) {
        return stop.locationCode + stop.grouping;
      }

      const currentStopsByKey = currentStops.reduce((acc, stop) => {
        const key = getStopKey(stop);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(stop);
        return acc;
      }, {});

      let statsPerLocationAndGrouping = {};
      stops.forEach(({ _id: { locationCode, grouping }, hours }) => {
        const location = locationsByCode[locationCode];
        const stopKey = getStopKey({ locationCode, grouping });

        // add all the current stops for this location
        if (currentStopsByKey[stopKey]) {
          currentStopsByKey[stopKey].forEach((currentStop) => {
            hours.push(currentStop.hours);
          });
        }

        const [availabilities, histogram] = epochHoursToHistogram(
          startEpochHour,
          endEpochHour,
          hours,
        );
        const instanceArray = Object.values(histogram).map((h) =>
          new Array(h.hours).fill(h.count),
        );
        const std = standardDeviation(instanceArray);
        const mean = meanValue(instanceArray);

        const normalDistribution = new NormalDistribution(mean, std);
        function invp(p) {
          const result = normalDistribution.invCumulativeProbability(1 - p);
          return result > 0 ? result : 0;
        }

        statsPerLocationAndGrouping[stopKey] = {
          stopKey,
          location: location?.name || locationCode,
          // locationType: location?.type || 'Unknown',
          grouping,
          // p95: inv95 * std,
          // p975: 2.5 * std, //inv975 * std,
          // p99: inv99 * std,
          pCustom: invp(customConfidence / 100),
          p95: invp(0.95),
          p975: invp(0.975),
          p99: invp(0.99),
          std,
          mean,
          availabilities,
          histogram,
        };
      });

      const data = Object.values(statsPerLocationAndGrouping);

      // TODOJL!
      const filterOptions = {
        location: Array.from(new Set(data.map((l) => l.location))).sort(),
        // locationType: _.uniq(data.map((l) => l.locationType)).sort(),
        grouping: Array.from(new Set(data.map((l) => l.grouping))).sort(),
      };

      const filteredData = data.filter((record) =>
        Object.keys(filter).every(
          (key) =>
            (filter[key]?.length || 0) === 0 ||
            filter[key].includes(record[key]),
        ),
      );

      log('Read', 'Vehicle Availability', {
        startTime,
        endTime,
      });

      return {
        filterOptions,
        filteredData,
        data,
      };
    },
    placeholderData: {
      filterOptions: {},
      filteredData: [],
      data: [],
    },
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
