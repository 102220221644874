import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useVehicle(id) {
  const queryKey = ['vehicles', id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (id === 'new') {
        return {
          identificationNumber: '',
        };
      }

      const json = [
        { $match: { identificationNumber: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            picture: true,
            role: true,
            type: true,
            make: true,
            model: true,
            keyNumber: true,
            colour: true,
            marked: true,
            homeStation: true,
            equipment: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            odometerReadings: {
              $sortArray: {
                input: {
                  $map: {
                    input: { $ifNull: ['$odometerReadings', []] },
                    in: {
                      key: { $indexOfArray: ['$odometerReadings', '$$this'] },
                      time: '$$this.time',
                      miles: {
                        $round: [
                          {
                            $multiply: ['$$this.kilometres', 0.62137119],
                          },
                          3,
                        ],
                      },
                    },
                  },
                },
                sortBy: { time: -1 },
              },
            },
            fuelType: true,
            disposalDate: true,
            driverIdLocation: true,
            driverIdLocationImage: true,
            installLocation: true,
            installLocationImage: true,
            commissionDate: true,
            notes: true,
            groups: true,
            assignments: true,
            visibleTo: true,
            attributes: true,
            groupCodes: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: {
                      $filter: {
                        input: '$groupAncestors',
                        cond: {
                          $not: [{ $in: ['$$ancestor.code', '$groupCodes'] }],
                        },
                        as: 'ancestor',
                      },
                    },
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  code: '$$group.code',
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
            lastEdit: true,
          },
        },
      ];

      const vehicles = await api
        .post('pipeline/vehicles', { json, signal })
        .json();

      log('Read', 'Vehicles', { id });

      return vehicles[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!id,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
