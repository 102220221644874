import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useGroupDescendants() {
  const queryKey = ['groupDescendants'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$code',
            connectFromField: 'code',
            connectToField: 'parentCodes',
            as: 'decendentCodes',
          },
        },
        {
          $project: {
            _id: false,
            code: true,
            decendentCodes: {
              $map: {
                input: '$decendentCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
          },
        },
      ];

      const groups = await api.post('pipeline/groups', { json, signal }).json();

      log('Read', 'Groups');

      return groups.reduce((acc, group) => {
        acc[group.code] = [group.code, ...group.decendentCodes];

        return acc;
      }, {});
    },
    placeholderData: (previousData) => previousData ?? {},
    staleTime: 1000 * 60 * 60 * 12,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
