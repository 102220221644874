import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useRadio(id) {
  const queryKey = ['radios', id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (id === 'new') {
        return {
          label: '',
        };
      }

      const json = [
        { $match: { ssi: id } },
        {
          $project: {
            ssi: true,
            type: true,
            label: true,
            firstContactTime: true,
            lastPoll: '$lastRadioPoll',
            lastEdit: true,
          },
        },
        {
          $lookup: {
            from: 'people',
            localField: 'ssi',
            foreignField: 'radioSsi',
            as: 'people',
          },
        },
        {
          $set: {
            people: {
              $filter: {
                input: '$people',
                as: 'person',
                cond: { $ne: ['$$person.deleted', true] },
              },
            },
          },
        },
      ];

      const radios = await api.post('pipeline/radios', { json, signal }).json();

      log('Read', 'Radios', { id });

      return radios[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
