import { getStatusColor } from '@/utils';
import { Layer, Source } from 'react-map-gl/maplibre';

export function VehiclesSource({ id, data, visibility }) {
  return (
    <Source id={id} type="geojson" data={data}>
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'ir3-teardrop',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.125, 12, 1],
          'icon-rotate': ['get', 'headingDegrees'],
          visibility,
        }}
        paint={{
          'icon-color': [
            'match',
            ['get', 'status'],
            'emergencyEquipmentOn',
            getStatusColor('emergencyEquipmentOn'),
            'ignitionOff',
            getStatusColor('ignitionOff'),
            'ignitionOn',
            getStatusColor('ignitionOn'),
            'malfunctionIndicatorLightOn',
            getStatusColor('malfunctionIndicatorLightOn'),
            getStatusColor('default'),
          ],
          'icon-halo-color': '#fff',
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'icon-image': ['case', ['has', 'vehicle'], 'ir3-car', 'ir3-box'],
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.075, 12, 0.6],
          visibility,
        }}
        paint={{
          'icon-color': '#fff',
        }}
      />
    </Source>
  );
}
