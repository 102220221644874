import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function addType(result) {
  result.display_name = `${result?.display_name} (${result?.type})`;
}

export function useGazetteer(search) {
  const queryKey = ['gazetteer', search];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      log('Read', 'Gazetteer', { search });

      const results = await api
        .get('nominatim/search', {
          searchParams: { q: search, format: 'json' },
          signal,
        })
        .json();

      let names = {};
      results.forEach((result, index) => {
        if (result.display_name in names) {
          // don't add type multiple times to the first one
          if (names[result.display_name] !== -1) {
            // addType(response[names[result.display_name]]);
            names[result.display_name] = -1;
          }

          addType(result);
        } else {
          names[result.display_name] = index;
        }
      });

      return results;
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!search,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
