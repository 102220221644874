import { round, shortHumanizer } from '@/utils';
import { events, useDallasKeys } from '@/utils/config';
import { format } from 'date-fns';
import { atom } from 'jotai';
import { Occupants } from './Occupants';

const { showTripOccupants = false } = events;

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: { pageIndex: 0, pageSize: 25 },
  rowSelection: [],
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting, categoryTypes = []) =>
  [
    { $match: query },
    {
      $project: {
        _id: false,
        identifier: true,
        equipmentActivations: { emergencyOn: true },
        driver: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          role: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: categoryTypes.reduce(
            (acc, value) => ({
              ...acc,
              [value]: true,
            }),
            {},
          ),
          type: true,
          telematicsBoxImei: true,
        },
        rfidCard: {
          reference: true,
          type: true,
          label: true,
        },
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceMiles: { $multiply: ['$distanceKilometres', 0.62137119] },
        maxSpeedMilesPerHour: {
          $multiply: ['$maxSpeedKilometresPerHour', 0.62137119],
        },
        startLocation: {
          $ifNull: [
            { $first: '$startLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        endLocation: {
          $ifNull: [
            { $first: '$endLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        classification: { $ifNull: ['$classification', 'None'] },
        occupants: true,
        occupantCount: {
          $cond: [{ $not: ['$occupants'] }, -1, { $size: '$occupants' }],
        },
        occupantsReasonCode: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      maxSpeedKilometresPerHour: { $max: '$maxSpeedKilometresPerHour' },
      distanceKilometres: { $sum: '$distanceKilometres' },
    },
  },
  {
    $set: {
      maxSpeedMilesPerHour: {
        $round: [
          {
            $multiply: ['$maxSpeedKilometresPerHour', 0.62137119],
          },
          2,
        ],
      },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn =
  (collection, categoryTypes = []) =>
  (query) => [
    { $match: query },
    {
      $project: {
        _id: false,
        identifier: true,
        equipmentActivations: {
          emergencyOn: {
            $cond: ['$equipmentActivations.emergencyOn', 'Yes', 'No'],
          },
        },
        driver: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          role: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: categoryTypes.reduce(
            (acc, value) => ({
              ...acc,
              [value]: true,
            }),
            {},
          ),
          type: true,
          telematicsBoxImei: true,
        },
        rfidCard: true,
        startTime: true,
        endTime: true,
        durationSeconds: { $divide: ['$durationSeconds', 86400] },
        distanceMiles: {
          $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
        },
        maxSpeedMilesPerHour: {
          $round: [
            { $multiply: ['$maxSpeedKilometresPerHour', 0.62137119] },
            2,
          ],
        },
        startLocation: {
          $ifNull: [
            { $first: '$startLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        endLocation: {
          $ifNull: [
            { $first: '$endLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        classification: { $ifNull: ['$classification', 'None'] },
        occupantCount: {
          $map: {
            input: '$occupants',
            as: 'occupant',
            in: {
              $trim: {
                input: {
                  $concat: [
                    '$$occupant.forenames',
                    ' ',
                    '$$occupant.surname',
                    ' [',
                    '$$occupant.collarNumber',
                    '] ',
                    {
                      $toString: {
                        $round: [{ $multiply: ['$$occupant.ratio', 100] }],
                      },
                    },
                    '% ',
                    {
                      $toString: {
                        $round: [
                          {
                            $multiply: [
                              '$$occupant.distanceKilometres',
                              0.62137119,
                            ],
                          },
                          2,
                        ],
                      },
                    },
                    ' mi',
                  ],
                },
              },
            },
          },
        },
        link: {
          $concat: [
            `${window.location.origin}/eventreplay/${collection}/`,
            '$identifier',
          ],
        },
      },
    },
  ];

export const columnsFn = (
  footer,
  canEdit,
  tripClassifications,
  categories = [],
) =>
  [
    {
      header: 'Registration',
      id: 'vehicle.registrationNumber',
      accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
        registrationNumber ?? '',
    },
    {
      header: 'Fleet Number',
      id: 'vehicle.fleetNumber',
      accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
    },
    {
      header: 'Role',
      id: 'vehicle.role',
      accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
    },
    ...categories.map(({ value, label }) => ({
      header: label,
      id: `vehicle.groups.${value}`,
      accessorFn: ({ vehicle: { groups = {} } = {} } = {}) =>
        groups[value]?.join(', ') ?? '',
    })),
    {
      header: 'Type',
      id: 'vehicle.type',
      accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'Staff ID',
      id: 'driver.code',
      accessorFn: ({ driver: { code } = {} } = {}) => code ?? '',
    },
    {
      header: 'Forenames',
      id: 'driver.forenames',
      accessorFn: ({ driver: { forenames } = {} } = {}) => forenames ?? '',
    },
    {
      header: 'Surname',
      id: 'driver.surname',
      accessorFn: ({ driver: { surname } = {} } = {}) => surname ?? '',
    },
    {
      header: 'Collar Number',
      id: 'driver.collarNumber',
      accessorFn: ({ driver: { collarNumber } = {} } = {}) =>
        collarNumber ?? '',
    },
    {
      header: 'Driver Role',
      id: 'driver.role',
      accessorFn: ({ driver: { role } = {} } = {}) => role ?? '',
    },
    {
      header: 'IMEI',
      id: 'vehicle.telematicsBoxImei',
      accessorFn: ({ vehicle: { telematicsBoxImei } = {} } = {}) =>
        telematicsBoxImei ?? '',
    },
    {
      header: 'Warning Equipment',
      id: 'equipmentActivations.emergencyOn',
      accessorFn: ({ equipmentActivations: { emergencyOn } = {} } = {}) =>
        emergencyOn ? 'Yes' : 'No',
    },
    {
      header: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      id: 'rfidCard.reference',
      accessorFn: ({ rfidCard: { reference } = {} } = {}) => reference ?? '',
    },
    {
      header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
      id: 'rfidCard.type',
      accessorFn: ({ rfidCard: { type } = {} } = {}) => type ?? '',
    },
    {
      header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
      id: 'rfidCard.label',
      accessorFn: ({ rfidCard: { label } = {} } = {}) => label ?? '',
    },
    {
      header: 'Classification',
      accessorKey: 'classification',
      enableEditing: canEdit,
      editVariant: 'select',
      editSelectOptions: [
        { value: null, text: 'None' },
        ...tripClassifications.map(({ value, label }) => ({
          value,
          text: label,
        })),
      ],
    },
    {
      header: 'Start Location Type',
      id: 'startLocation.type',
      accessorFn: ({ startLocation: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'Start Location Name',
      id: 'startLocation.name',
      accessorFn: ({ startLocation: { name } = {} } = {}) => name ?? '',
    },
    {
      header: 'End Location Type',
      id: 'endLocation.type',
      accessorFn: ({ endLocation: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'End Location Name',
      id: 'endLocation.name',
      accessorFn: ({ endLocation: { name } = {} } = {}) => name ?? '',
    },
    {
      header: 'Start Time',
      accessorKey: 'startTime',
      Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      header: 'End Time',
      accessorKey: 'endTime',
      Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      header: 'Duration',
      accessorKey: 'durationSeconds',
      Cell: ({ cell }) =>
        Number.isNaN(cell.getValue())
          ? ''
          : shortHumanizer(cell.getValue() * 1000),
      footer: Number.isNaN(footer.durationSeconds)
        ? ''
        : shortHumanizer(footer.durationSeconds * 1000),
    },
    {
      header: 'Distance',
      accessorKey: 'distanceMiles',
      Cell: ({ cell }) =>
        Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mi`,
      footer: Number.isNaN(footer.distanceMiles)
        ? ''
        : `${round(footer.distanceMiles, 2)} mi`,
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      muiTableFooterCellProps: { align: 'right' },
    },
    {
      header: 'Maximum Speed',
      accessorKey: 'maxSpeedMilesPerHour',
      Cell: ({ cell }) =>
        Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mph`,
      footer: Number.isNaN(footer.maxSpeedMilesPerHour)
        ? ''
        : `${round(footer.maxSpeedMilesPerHour, 2)} mph`,
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      muiTableFooterCellProps: { align: 'right' },
    },
    showTripOccupants && {
      header: 'Detected Occupants',
      accessorKey: 'occupantCount',
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      Cell: ({ row: { original } }) => <Occupants entry={original} />,
    },
  ].filter(Boolean);
