import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfWeek } from 'date-fns';

export function useObjectiveAttendeeCounts(objectiveId, groupType, groupCode) {
  const queryKey = [
    'topFiveObjectiveAttendees',
    objectiveId,
    groupType,
    groupCode,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const weekStart = startOfWeek(new Date());
      const json = [
        {
          $match: {
            startTime: { $gt: weekStart },
            'objective.identifier': objectiveId,
            'person.groupCodes': groupCode,
          },
        },
        {
          $group: {
            _id: '$person.collarNumber',
            attendances: { $sum: 1 },
            durationSeconds: { $sum: '$durationSeconds' },
          },
        },
        {
          $project: {
            _id: false,
            collarNumber: '$_id',
            attendances: true,
            durationSeconds: true,
          },
        },
        { $sort: { durationSeconds: -1 } },
      ];

      return api
        .post('pipeline/personObjectiveAttendances', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
