import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useUserQueryUserIds() {
  const queryKey = ['userQueries', 'users'];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        { $group: { _id: null, userId: { $addToSet: '$created.userId' } } },
        { $unwind: '$userId' },
        { $project: { _id: 0 } },
        { $sort: { userId: 1 } },
      ];
      const results = await api
        .post('pipeline/userQueries', { json, signal })
        .json();

      return results.map(({ userId }) => userId);
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
