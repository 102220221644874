import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useLocation(id, defaultValue = { name: '' }) {
  const queryKey = ['locations', id];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (!id || id === 'new') {
        return defaultValue;
      }

      const json = [
        { $match: { code: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            code: true,
            name: true,
            type: true,
            district: true,
            subtype: true,
            boundary: true,
            startTime: true,
            endTime: true,
            picture: true,
            aliases: true,
            groups: true,
            attributes: true,
            groupCodes: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: {
                      $filter: {
                        input: '$groupAncestors',
                        cond: {
                          $not: [{ $in: ['$$ancestor.code', '$groupCodes'] }],
                        },
                        as: 'ancestor',
                      },
                    },
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  code: '$$group.code',
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
            lastEdit: true,
          },
        },
      ];

      const locations = await api
        .post('pipeline/locations', { json, signal })
        .json();

      log('Read', 'Locations', { id });

      return locations[0] ?? null;
    },
    staleTime: 30 * 60 * 1000,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
