import { mapStyleAtom } from '@/atoms';
import { MapBranding } from '@/components/controls';
import { transformRequest } from '@/utils';
import { maxBounds, maxZoom, minZoom } from '@/utils/config';
import { Backdrop, Box, CircularProgress, useTheme } from '@mui/material';
import { bbox } from '@turf/turf';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map as ReactMapGL, ScaleControl } from 'react-map-gl/maplibre';
import { replayActionsAtom } from '../utils';
import './Map.css';
import { MapControls } from './MapControls';
import { MapImage } from './MapImage';

export function Map({
  sx,
  viewState: initialViewState,
  interactiveLayerIds,
  onMouseMove,
  children,
}) {
  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    ...initialViewState,
  });
  const [style, setStyle] = useAtom(mapStyleAtom);
  const [{ scrollTo }, setActions] = useAtom(replayActionsAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cursor, setCursor] = useState('auto');
  const mapRef = useRef();
  const theme = useTheme();

  const panToFn = useCallback((feature) => {
    if (feature) {
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);

      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000, maxZoom: 16 },
      );
    }
  }, []);
  useEffect(() => {
    setActions((actions) => ({ ...actions, panTo: panToFn }));
  }, [panToFn, setActions]);

  function handleClick(event) {
    scrollTo(event.features?.[0]);
  }

  function handleLoad() {
    setIsLoaded(true);
  }

  function handleError(errorEvent) {
    setIsLoaded(true);

    throw errorEvent.error;
  }

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleChange(path) {
    setStyle(path);
  }

  const handleMouseEnter = useCallback(() => setCursor('pointer'), []);
  const handleMouseLeave = useCallback(() => setCursor('auto'), []);

  return (
    <Box
      sx={{
        position: 'relative',
        '& .maplibregl-popup-content': {
          backgroundColor: theme.palette.background.paper,
        },
        '& .maplibregl-popup-anchor-top .maplibregl-popup-tip': {
          borderBottomColor: theme.palette.background.paper,
        },
        '& .maplibregl-popup-anchor-bottom .maplibregl-popup-tip': {
          borderTopColor: theme.palette.background.paper,
        },
        '& .maplibregl-popup-anchor-left .maplibregl-popup-tip': {
          borderRightColor: theme.palette.background.paper,
        },
        '& .maplibregl-popup-anchor-right .maplibregl-popup-tip': {
          borderLeftColor: theme.palette.background.paper,
        },
        ...sx,
      }}
    >
      <ReactMapGL
        {...viewState}
        ref={mapRef}
        mapStyle={style}
        onMove={handleMove}
        transformRequest={transformRequest(style)}
        interactiveLayerIds={interactiveLayerIds}
        onMouseMove={onMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        cursor={cursor}
        onLoad={handleLoad}
        onError={handleError}
        dragRotate={false}
      >
        <MapImage sdf name="ir3-teardrop" url="/sdf/teardrop.png" />
        <MapImage sdf name="ir3-circle" url="/sdf/circle.png" />
        <MapImage sdf name="ir3-square" url="/sdf/square.png" />
        <MapImage sdf name="ir3-hexagon" url="/sdf/hexagon.png" />
        <MapImage sdf name="ir3-house" url="/sdf/house.png" />
        <MapImage sdf name="ir3-triangle" url="/sdf/triangle.png" />
        <MapImage sdf name="ir3-car" url="/sdf/car.png" />
        <MapImage sdf name="ir3-person" url="/sdf/person.png" />
        <MapImage sdf name="ir3-police-station" url="/sdf/police-station.png" />
        <MapImage sdf name="ir3-base" url="/sdf/base.png" />
        <MapImage sdf name="ir3-workshop" url="/sdf/workshop.png" />
        <MapImage sdf name="ir3-hospital" url="/sdf/hospital.png" />
        <MapImage sdf name="ir3-school" url="/sdf/school.png" />
        <MapImage sdf name="ir3-court" url="/sdf/court.png" />
        <MapImage sdf name="ir3-event" url="/sdf/event.png" />
        <MapImage sdf name="ir3-box" url="/sdf/box.png" />
        <MapImage sdf name="ir3-radio" url="/sdf/radio.png" />
        <MapImage sdf name="ir3-accelerometer" url="/sdf/accelerometer.png" />
        <MapImage sdf name="ir3-objective" url="/sdf/objective.png" />
        <ScaleControl />
        <MapControls style={style} onStyleChange={handleStyleChange} />
        <MapBranding style={style} />
        {children}
      </ReactMapGL>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={!isLoaded}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}
