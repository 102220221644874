import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useSharedMissingPerson(personId, defaultValue) {
  const queryKey = ['sharedMissingPersons', personId, defaultValue];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const existingShare = await briefingApi
        .get(`shared-missing-persons/${personId}`, { signal })
        .json();

      if (existingShare?.code) {
        return existingShare;
      } else {
        return defaultValue;
      }
    },
    enabled: !!personId,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
