import { api } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useResponseTimesByHour(
  groupType,
  groupCode,
  subgroupType,
  days,
) {
  const queryKey = [
    'responseTimesByHour',
    groupType,
    groupCode,
    subgroupType,
    days,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            openedTime: { $gt: start },
            [`ward.groups.${groupType}`]: groupCode,
            attendedTime: { $exists: true },
          },
        },
        {
          $facet: {
            all: [
              {
                $group: {
                  _id: {
                    grade: '$grade',
                    hour: { $hour: { date: '$openedTime', timezone: 'GB' } },
                  },
                  averageResponseTimeInHours: {
                    $avg: {
                      $divide: [
                        {
                          $dateDiff: {
                            startDate: '$openedTime',
                            endDate: '$attendedTime',
                            unit: 'second',
                          },
                        },
                        3600,
                      ],
                    },
                  },
                },
              },
              {
                $project: {
                  _id: false,
                  identifier: 'All',
                  grade: '$_id.grade',
                  hour: '$_id.hour',
                  averageResponseTimeInHours: true,
                },
              },
              { $sort: { grade: 1, hour: 1 } },
            ],
            groups: [
              subgroupType !== 'WARD' && {
                $unwind: {
                  path: `$ward.groups.${subgroupType}`,
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $group: {
                  _id: {
                    grade: '$grade',
                    identifier: {
                      $ifNull: [
                        subgroupType === 'WARD'
                          ? '$ward.code'
                          : `$ward.groups.${subgroupType}`,
                        'UNKNOWN',
                      ],
                    },
                    hour: { $hour: { date: '$openedTime', timezone: 'GB' } },
                  },
                  averageResponseTimeInHours: {
                    $avg: {
                      $dateDiff: {
                        startDate: '$openedTime',
                        endDate: '$attendedTime',
                        unit: 'hour',
                      },
                    },
                  },
                },
              },
              {
                $project: {
                  _id: false,
                  grade: '$_id.grade',
                  identifier: '$_id.identifier',
                  hour: '$_id.hour',
                  averageResponseTimeInHours: true,
                },
              },
              { $sort: { identifier: 1, grade: 1, hour: 1 } },
            ].filter(Boolean),
          },
        },
      ];

      const data = await api
        .post('pipeline/incidents', { json, signal })
        .json();

      return {
        groups: [
          'All',
          ...new Set(data[0].groups.map((group) => group.identifier)),
        ],
        grades: [...new Set(data[0].all.map((group) => group.grade))],
        grouped: data[0].all
          .concat(data[0].groups)
          .reduce(
            (
              accumlator,
              { grade, identifier, hour, averageResponseTimeInHours },
            ) => {
              if (!accumlator[grade]) {
                accumlator[grade] = {};
              }
              if (!accumlator[grade][hour]) {
                accumlator[grade][hour] = {};
              }

              accumlator[grade][hour][identifier] = averageResponseTimeInHours;

              return accumlator;
            },
            {},
          ),
      };
    },
    placeholderData: { grouped: {}, groups: [], grades: [] },
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
