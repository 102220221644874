import { briefingApi } from '@/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useWatch(code) {
  const queryKey = ['watches', code];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      if (code === 'new') {
        return {
          name: '',
          custodyNumber: '',
          occurrenceNumber: '',
          arrestingOfficerCode: '',
          investigatingOfficerCode: '',
          officerInChargeCode: '',
          arrivalTime: null,
          custodyUnit: '',
          observationLevel: '',
        };
      }

      return briefingApi.get(`watches/${code}`, { signal }).json();
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
