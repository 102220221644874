import {
  BoundaryEditor,
  GroupCodesController,
  SwitchController,
} from '@/components/controls';
import { isIncidentNumber, isOccurrenceNumber } from '@/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export function SceneEditorDialog({
  scene,
  isOpen,
  onClose,
  onDelete,
  groupCodes,
}) {
  const { control, handleSubmit, reset, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: scene,
  });

  useEffect(() => {
    reset(scene);
  }, [reset, scene, isOpen]);

  function handleClose() {
    onClose();
  }

  return (
    <form id="scene-editor-form" onSubmit={handleSubmit(onClose)}>
      <Dialog
        open={isOpen && !!scene}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{`${scene.code ? 'Edit' : 'Add'} Scene`}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            Please fill in the form below to add a scene. If groups are not
            specified then the scene will be visible to all.
          </DialogContentText>
          <Stack spacing={1} direction="row">
            <Stack spacing={1} sx={{ width: 240 }}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: 'Required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Name"
                    error={!!error}
                    helperText={error?.message}
                    sx={{ minWidth: 200 }}
                  />
                )}
              />
              <Controller
                name="attributes.incidentCode"
                control={control}
                defaultValue=""
                rules={{
                  validate: async (value) =>
                    !value || (await isIncidentNumber(value)),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Incident"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="attributes.occurrenceNumber"
                control={control}
                defaultValue=""
                rules={{
                  validate: async (value) =>
                    !value || (await isOccurrenceNumber(value)),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Occurrence"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="startTime"
                control={control}
                defaultValue={null}
                rules={{ required: 'Required' }}
                render={({
                  field: { value, disabled, onChange, onBlur, ref },
                  fieldState: { error, invalid },
                }) => (
                  <DateTimePicker
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    label="Start Time"
                    minDate={new Date('1900-01-01')}
                    maxDate={watch('endTime') ?? new Date('2100-01-01')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: invalid,
                        helperText: error?.message,
                        onBlur,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="endTime"
                control={control}
                defaultValue={null}
                render={({
                  field: { value, disabled, onChange, onBlur, ref },
                  fieldState: { error, invalid },
                }) => (
                  <DateTimePicker
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    label="End Time"
                    minDate={watch('startTime') ?? new Date('1900-01-01')}
                    maxDate={new Date('2100-01-01')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: invalid,
                        helperText: error?.message,
                        onBlur,
                      },
                    }}
                  />
                )}
              />
              <GroupCodesController
                label="Groups"
                control={control}
                name="groupCodes"
                ancestors={groupCodes}
              />
              <Controller
                name="attributes.requiredAttendance"
                control={control}
                defaultValue={0}
                type="number"
                valueAsNumber
                rules={{ required: 'Required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Required Attendance"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Box sx={{ flexGrow: 1 }} />
              <SwitchController
                name="attributes.showOnLiveMap"
                label="Show on Live Map"
                control={control}
              />
            </Stack>
            <Controller
              name="boundary"
              control={control}
              rules={{ required: 'Required' }}
              render={({
                field: { value, disabled, onChange },
                fieldState: { error, isDirty },
              }) => (
                <BoundaryEditor
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  error={error?.message}
                  isDirty={isDirty}
                  sx={{ width: '100%', maxHeight: 1000, minHeight: 640 }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!!scene.code && (
            <Fragment>
              <Button color="error" onClick={onDelete}>
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
            </Fragment>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="scene-editor-form" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
